<template>
	<AppScreen name="tutorial">
		<template #content>
			<p class="lead">
				Thank you for agreeing to participate in our research!
				<br /> We will be using an activity called <strong>the Care/Work Portrait</strong>
			</p>
			<AppCarousel>
				<div class="tutorial-slide">
					<h2>What is it?</h2>
					<img :src="require( '@/assets/img/slide-columns.png' )" />
					<p>The Care/Work Portrait is an activity to learn about how you organize and divide up different kinds of work and care tasks and responsibilities in your household.</p>
					<p>The activity is a bit like a board game. You will decide where to place cards about who does what. You’ll construct a portrait of your tasks and responsibilities and tell stories about how and why you do things the way you do.</p>
				</div>
				<div class="tutorial-slide">
					<h2>How does it work?</h2>
					<img :src="require( '@/assets/img/slide-categories.png' )" />
					<p>In the portrait, we will look at tasks and responsibilities related to (1) child(ren) care; (2) household work, (3) extended family and community work; (4) paid work; and (5) self-care.</p>
				</div>
				<div class="tutorial-slide">
					<h2>We will ask you to select:</h2>
					<img :src="require( '@/assets/img/slide-assign.png' )" />
				</div>
				<div class="tutorial-slide">
					<h2>You will also have</h2>
					<img :src="require( '@/assets/img/slide-supports.png' )" />
					<p>The option to indicate if you receive additional paid or unpaid supports or services, or if that task is not currently part of your daily life.</p>
				</div>
				<div class="tutorial-slide">
					<h2>In each category</h2>
					<img :src="require( '@/assets/img/slide-responsibilities.png' )" />
					<p>We will start by asking you about who does particular <strong>tasks</strong>, and then ask about <strong>responsibilities</strong>.</p>
					<p>Responsibilities are about noticing, making decisions and putting a plan in place, making sure the work gets done, and monitoring and making changes as needed. Some people call this ‘the mental load’ or household management and administration. This is not about doing the work - but the thinking and planning, the emotional and cognitive work.</p>
				</div>
				<div class="tutorial-slide">
					<h2>This isn’t a scoreboard or competition!</h2>
					<p>The portrait isn’t a scoreboard or competition to see who does more. We won’t be judging anyone for doing too much or too little. We acknowledge that housework and care work can be experienced differently by different people, that families find what works for them, and that many things change over time. We are especially interested in how you juggle and manage household and care work, paid work, and other kinds of work – and with what supports and services.</p>
					<p>Our goal is to facilitate discussion and reflection. We are interested in your shared and individual views. Feel free to add or clarify anything or tell a different story. There are no right answers.</p>
				</div>
				<div class="tutorial-slide">
					<h2>What is the time period for the Care/Work Portrait?</h2>
					<p>The focus of the portrait will be on your lives now. We will also ask about changes in the last 12 months or so – and more broadly, how things have changed over time.</p>
					<p>We hope that you enjoy this activity!</p>
				</div>
			</AppCarousel>
		</template>

		<template #footer>
			<AppButton route="/consent" color="black">
				Back
			</AppButton>
			<AppButton route="/profiles" color="pink">
				Sounds Good
			</AppButton>
		</template>
	</AppScreen>
</template>

<script>
import AppScreen from '../components/AppScreen';
import AppButton from '../components/AppButton';
import AppCarousel from '../components/AppCarousel.vue';

export default {
	components: {
		AppScreen,
		AppButton,
		AppCarousel,
	},
};
</script>
