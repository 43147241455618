export default {
	install( Vue, store ) {
		Vue.mixin( {
			methods: {
				showModal( name ) {
					store.commit( 'showModal', name );
				},
				hideModal( name ) {
					store.commit( 'hideModal', name );
				},
				toggleModal( name, open ) {
					if ( typeof open === 'undefined' ) {
						open = store.state.activeModals.includes( name );
					}

					if ( open ) {
						return this.showModal( name );
					}

					return this.hideModal( name );
				},
			},
		} );
	},
};
