import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Consent from '../views/Consent.vue';
import Tutorial from '../views/Tutorial.vue';
import Profiles from '../views/Profiles.vue';
import Supports from '../views/Supports.vue';
import Tasks from '../views/Tasks.vue';
import Stats from '../views/Stats.vue';
import Summary from '../views/Summary.vue';

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/consent',
		name: 'consent',
		component: Consent,
	},
	{
		path: '/tutorial',
		name: 'tutorial',
		component: Tutorial,
	},
	{
		path: '/profiles',
		name: 'profiles',
		component: Profiles,
	},
	{
		path: '/supports',
		name: 'supports',
		component: Supports,
		props: {
			back: '/profiles',
			next: '/tasks',
		},
	},
	{
		path: '/tasks',
		name: 'tasks',
		component: Tasks,
	},
	{
		path: '/supports2',
		name: 'supports2',
		component: Supports,
		props: {
			back: '/tasks',
			next: '/summary',
		},
	},
	{
		path: '/stats',
		name: 'stats',
		component: Stats,
	},
	{
		path: '/summary',
		name: 'summary',
		component: Summary,
	},
];

const router = new VueRouter( {
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
} );

export default router;
