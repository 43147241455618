<template>
	<AppScreenSection :title="title" edit="/profiles">
		<div class="profile-group-wrapper">
			<div class="profile-group">
				<div class="profile__header">
					<h3 class="profile__header__title">
						Participants
					</h3>
					<div class="profile__tag">
						{{ 2 }}
					</div>
				</div>
				<div class="profile-group__inner">
					<ProfileCard v-bind="profile1" />
					<ProfileCard v-bind="profile2" />
				</div>
			</div>
		</div>
	</AppScreenSection>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AppScreenSection from '../components/AppScreenSection.vue';
import ProfileCard from '../components/ProfileCard.vue';

export default {
	components: {
		AppScreenSection,
		ProfileCard,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState( [ 'profile1', 'profile2', 'otherProfile'] ),
		...mapGetters( [ 'hasOtherProfile' ] ),
	},
};
</script>
