<template>
	<AppScreenSection :title="title">
		<div class="chart-card-group">
			<ChartCard
				v-for="group in assignmentSummaries"
				:key="group.id"
				:avatar="group.avatar"
				:label="group.label"
				:datum="getDatumForGroup( group.id )"
			/>
		</div>
	</AppScreenSection>
</template>

<script>
import { mapGetters } from 'vuex';
import { forOwn } from 'lodash';
import CATEGORIES from '../store/categories.json';
import AppScreenSection from '../components/AppScreenSection.vue';
import ChartCard from '../components/ChartCard.vue';

export default {
	components: {
		AppScreenSection,
		ChartCard,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			categories: CATEGORIES,
		};
	},
	computed: {
		...mapGetters( [ 'assignments', 'getTasksForAssignment' ] ),

		assignmentSummaries() {
			return this.assignments.filter( d => d.hasStats );
		},
	},
	methods: {
		getDatumForGroup( group ) {
			const tasks = this.getTasksForAssignment( group );
			const datum = [];

			forOwn( this.categories, ( { id, label, color, hasStats } ) => {
				if ( hasStats ) {
					const matches = tasks.filter( t => t.category === id );

					datum.push( {
						label,
						color,
						value: matches.length,
					} );
				}
			} );

			return datum;
		},
	},
};
</script>
