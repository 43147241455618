<template>
	<Component
		:is="tagName"
		:class="[ className, `is-color-${ color }` ]"
		:data-detail="details || null"
	>
		<AppButton v-if="details" class="info-button" is-plain @click="showModal( `${id}-details` )">
			<ScreenReaderText>What's this?</ScreenReaderText>
		</AppButton>

		<span :class="`${ className }__label`">{{ labelText }}</span>

		<Portal v-if="details" to="modals">
			<AppModal :name="`${id}-details`" class="task-details" is-mini>
				<p :class="[ 'task-details__tag', `is-color-${ color }` ]">
					{{ categoryName }}
				</p>
				<h3 class="task-details__title">
					{{ labelText }}
				</h3>
				<p>{{ detailText }}</p>
			</AppModal>
		</Portal>
	</Component>
</template>

<script>
import { get } from 'lodash';
import { mapState } from 'vuex';
import ScreenReaderText from './ScreenReaderText.vue';
import AppModal from './AppModal.vue';
import CATEGORIES from '../store/categories.json';
import AppButton from './AppButton.vue';

export default {
	components: {
		ScreenReaderText,
		AppModal,
		AppButton,
	},
	inheritAttrs: false,
	props: {
		id: {
			type: Number,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		category: {
			type: Number,
			required: true,
		},
		color: {
			type: String,
			default: 'blue',
		},
		details: {
			type: String,
			default: '',
		},
		requires: {
			type: Array,
			default: null,
		},
		isCard: Boolean,
	},
	data() {
		return {
			tagName: this.isCard ? 'div' : 'span',
			className: this.isCard ? 'task-card' : 'task-list__item',
			categoryName: CATEGORIES.find( c => c.id === this.category )?.label || 'Uncategorized',
		};
	},
	computed: {
		...mapState( [ 'profile1', 'profile2', 'children' ] ),

		labelText() {
			return this.getText( this.label );
		},

		detailText() {
			return this.getText( this.details );
		},
	},
	methods: {
		getText( text ) {
			if ( ! this.requires ) {
				return text;
			}

			return text.replace( /\[(.*?)\]/, get( this, this.requires, '$1' ) );
		},
	},
};
</script>
