<template>
	<div id="app" :class="{ 'is-loading': loading }">
		<RouterView v-if="ready" />

		<PortalTarget name="modals" multiple />

		<AppModal name="login" is-mini no-close-button>
			<form @submit.prevent="login">
				<div class="form__field">
					<label for="username">Username/Email</label>
					<input id="username" ref="username" type="text" required />
				</div>
				<div class="form__field">
					<label for="password">Password</label>
					<input id="password" ref="password" type="password" required />
				</div>
				<p v-if="error" v-html="error" />
				<AppButton color="black" type="submit">
					Log in
				</AppButton>
			</form>
		</AppModal>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import API from './store/api';
import { REQUESTED_SESSION } from './store/requested';
import AppModal from './components/AppModal.vue';
import AppButton from './components/AppButton.vue';

export default {
	components: {
		AppModal,
		AppButton,
	},
	data() {
		return {
			loading: false,
			ready: false,
			error: null,
		};
	},
	computed: mapState( [ 'authToken', 'lastScreen' ] ),
	watch: {
		authToken( token ) {
			// If token is uset, ask for login
			if ( ! token ) {
				this.showModal( 'login' );
			}
		},
	},
	mounted() {
		API.$on( 'request', ( { method } ) => {
			if ( method === 'GET' || method === 'POST' ) {
				this.loading = true;
			}
		} );
		API.$on( 'response', () => this.loading = false );
		API.$on( 'error', () => this.loading = false );

		if ( this.authToken ) {
			// Login with auth token before showing
			// views, otherwise lock out
			this.$store.dispatch( 'login', this.authToken )
				.then( () => {
					if ( REQUESTED_SESSION && this.$route.name === 'home' ) {
						this.$router.replace( { name: 'stats' } );
					}
					this.ready = true;
				} )
				.catch( ( error ) => {
					switch ( error.code ) {
						// Empty session, just lock out
						case 'empty_session':
							this.lockout();
							break;

						// Invalid account, show login
						default:
							this.showModal( 'login' );
							break;
					}
				} );
		} else {
			// No token, show login immediately
			this.showModal( 'login' );
		}
	},
	methods: {
		lockout() {
			// Force to homepage before showing views
			if ( this.$route.name !== 'home' ) {
				this.$router.replace( { name: 'home' } );
			}
			this.hideModal( 'login' );
			this.ready = true;
		},
		login() {
			this.error = null;

			this.$store.dispatch( 'login', {
				login: this.$refs.username.value,
				password: this.$refs.password.value,
			} )
				// We're good, show the app
				.then( () => {
					this.hideModal( 'login' );
					if ( REQUESTED_SESSION && this.$route.name === 'home' ) {
						this.$router.replace( { name: 'stats' } );
					}
					this.ready = true;
				} )
				.catch( ( error ) => {
					// Empty session, just lock out
					if ( error.code === 'empty_session' ) {
						this.lockout();
					} else {
						// Login error, display message
						this.error = error.message;
					}
				} );
		},
	},
};
</script>
