import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';

import modals from './mixins/modals';

import './assets/styles/app.scss';

Vue.config.productionTip = false;

Vue.use( PortalVue );
Vue.use( VueRouter );
Vue.use( modals, store );

// Update last screen on navigation
router.afterEach( ( to ) => {
	if ( to.name !== 'home' && to.name !== 'stats' ) {
		store.commit( 'updateLastScreen', to.name );
	}
} );

new Vue( {
	router,
	store,
	render: h => h( App ),
} ).$mount( '#app' );
