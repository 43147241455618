<template>
	<div
		v-if="tasks.length > 0"
		class="task-block"
	>
		<ProfileCard
			v-bind="avatar"
			:name="label"
		/>
		<AppDropdown
			v-if="filterOptions.length > 1"
			v-model="filter"
			placeholder="Filter By Category"
			class="is-style-small"
			:options="filterOptions"
			:reduce="option => option.id"
		>
			<template #option="category">
				<span :class="[ 'category-list__item', `is-color-${ category.color }` ]">{{ category.label }}</span>
			</template>
		</AppDropdown>
		<ul class="task-list">
			<li
				v-for="task in tasks"
				v-show="! filter || filter === task.category"
				:key="task.id"
			>
				<TaskItem v-bind="task" />
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileCard from '../components/ProfileCard.vue';
import AppDropdown from '../components/AppDropdown.vue';
import TaskItem from '../components/TaskItem.vue';
import CATEGORIES from '../store/categories.json';

export default {
	components: {
		ProfileCard,
		AppDropdown,
		TaskItem,
	},
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		avatar: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			filter: null,
		};
	},
	computed: {
		...mapGetters( [ 'getTasksForAssignment' ] ),

		tasks() {
			return this.getTasksForAssignment( this.id );
		},

		filterOptions() {
			const available = this.tasks.map( task => task.category );

			return CATEGORIES.filter( category => available.includes( category.id ) && category.hasBreakdown );
		},
	},
};
</script>
