<template>
	<AppScreen name="tasks">
		<div class="task-columns">
			<Dropzone
				v-for="zone in columnDropzones"
				:key="zone.id"
				ref="dropzones"
				:name="zone.id"
				:class="{
					'task-column': true,
					'is-active': activeDropzone === zone.id,
				}"
			>
				<button
					class="task-column__header"
					:disabled="completed"
					@click="quickAddTask( zone.id )"
				>
					<ProfileCard
						:name="zone.label"
						v-bind="zone.avatar"
					/>

					<span class="task-counter">
						<span v-if="getTasksByAssignment( zone.id ).length > 0">
							{{ getTasksByAssignment( zone.id ).length }}
						</span>
						<AppIcon
							v-else
							type="plus"
						/>
					</span>
				</button>

				<TransitionGroup
					tag="div"
					type="animation"
					class="task-list task-column__content"
				>
					<Draggable
						v-for="item in getTasksByAssignment( zone.id )"
						v-show="filter.includes( item.category )"
						:key="item.id"
						:subject="item"
						:target="zone.id"
						:enabled="! completed"
						@dragging="highlightColumn"
						@dragEnd="moveCardToDropzone"
					>
						<TaskItem v-bind="item" />
					</Draggable>
				</TransitionGroup>
			</Dropzone>
		</div>

		<div class="task-footer-wrapper">
			<div class="task-footer">
				<div
					v-if="! completed"
					class="task-deck-spacer"
				/>
				<button
					v-else
					:class="{
						'filter-toggle': true,
						'is-active': showFilter,
					}"
					@click="showFilter = ! showFilter"
				>
					Filter
				</button>

				<div class="task-buckets">
					<Dropzone
						v-for="zone in bucketDropzones"
						:key="zone.id"
						ref="dropzones"
						:name="zone.id"
						:class="{
							'task-dropzone': true,
							'is-active': activeDropzone === zone.id,
						}"
					>
						<button
							class="task-dropzone__button"
							@click="quickAddTask( zone.id )"
						>
							<ProfileCard
								:name="zone.label"
							/>

							<span class="task-counter">
								<span v-if="getTasksByAssignment( zone.id ).length > 0">
									{{ getTasksByAssignment( zone.id ).length }}
								</span>
								<AppIcon
									v-else
									type="plus"
								/>
							</span>
						</button>
					</Dropzone>
				</div>

				<div class="task-buttons">
					<AppButton
						v-show="assignmentHistory.length > 0"
						class="undo-button"
						color="grey"
						icon="undo"
						:is-small="completed"
						@click="undoAssignTask"
					>
						Undo
					</AppButton>

					<Dropzone
						ref="naDropzone"
						name="none"
						:component="AppButton"
						color="brown-light"
						icon="trashcan"
						:is-small="completed"
						:class="{
							'is-active': activeDropzone === 'none',
						}"
						@click="quickAddTask( 'none' )"
					>
						Not Applicable
					</Dropzone>

					<AppButton
						v-if="completed"
						class="next-button"
						route="/supports2"
						color="pink"
					>
						Finish
					</AppButton>
				</div>
			</div>
		</div>

		<div v-show="showFilter" class="filter-flyout">
			<p>Filter Tasks & Responsibilities</p>
			<FilterToggles
				v-model="filter"
				:options="$options.CATEGORIES"
			/>

			<AppButton
				color="black"
				is-small
				@click="showFilter = false"
			>
				Close
			</AppButton>
		</div>

		<div class="task-deck-wrapper">
			<Transition>
				<TransitionGroup
					v-if="! completed"
					tag="div"
					class="task-deck"
					type="animation"
				>
					<Draggable
						v-for="(item, k) in availableTasks"
						:key="item.id"
						:subject="item"
						:target="activeDropzone"
						:enabled="k === 0"
						@dragging="highlightColumn"
						@dragEnd="moveCardToDropzone"
					>
						<TaskItem
							v-bind="item"
							is-card
						/>
					</Draggable>
				</TransitionGroup>
			</Transition>
			<Transition mode="out-in">
				<div v-if="deckPopup.id && showDeckPopup" :key="deckPopup.id || 'none'" class="task-deck-popover">
					<p>{{ deckPopup.label }}</p>
					<p>
						<span
							v-if="deckPopup.hasTasks"
							:class="{
								'category-label': true,
								[`is-color-${ deckPopup.color }`]: deckPopup.color,
							}"
						>
							Tasks
						</span>
						&nbsp;
						<span
							v-if="deckPopup.hasDuties"
							:class="{
								'category-label': true,
								[`is-color-${ deckPopup.color }-light`]: deckPopup.color,
							}"
						>
							Responsibilities
						</span>
					</p>
					<AppButton color="grey" is-small @click="showDeckPopup = false">
						Close
					</AppButton>
				</div>
			</Transition>
		</div>

		<AppModal name="review" class="help-modal" is-jumbo>
			<AvatarGraphic
				v-bind="sharedDropzone.avatar"
			/>
			<h2>Thank you!</h2>
		</AppModal>
	</AppScreen>
</template>

<script>
import { get, noop } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';
import CATEGORIES from '../store/categories.json';
import AppScreen from '../components/AppScreen.vue';
import AppModal from '../components/AppModal.vue';
import AppButton from '../components/AppButton.vue';
import AppIcon from '../components/AppIcon.vue';
import Dropzone from '../components/Dropzone.vue';
import Draggable from '../components/Draggable.vue';
import TaskItem from '../components/TaskItem.vue';
import ProfileCard from '../components/ProfileCard.vue';
import AvatarGraphic from '../components/AvatarGraphic.vue';
import FilterToggles from '../components/FilterToggles.vue';

export default {
	CATEGORIES,

	components: {
		AppScreen,
		AppModal,
		AppButton,
		AppIcon,
		Draggable,
		Dropzone,
		TaskItem,
		ProfileCard,
		AvatarGraphic,
		FilterToggles,
	},
	data() {
		return {
			AppButton,
			filter: CATEGORIES.map( c => c.id ),
			activeDropzone: null,
			deckPopup: {},
			showFilter: false,
			showDeckPopup: true,
		};
	},
	computed: {
		...mapState( [
			'profile1',
			'profile2',
			'children',
			'assignmentHistory',
			'tasksByAssignment',
			'seenReviewInfo',
		] ),
		...mapGetters( [ 'assignments' ] ),

		columnDropzones() {
			return this.assignments.filter( d => d.location === 'column' );
		},
		bucketDropzones() {
			return this.assignments.filter( d => d.location === 'bucket' );
		},
		sharedDropzone() {
			return this.assignments.find( d => d.id === 'shared' );
		},
		getTasksByAssignment() {
			// Ensure all possible dependencies are accessed
			noop( this.profile1, this.profile2, this.children );

			return ( assignment ) => {
				return this.tasksByAssignment[ assignment ].filter( ( task ) => {
					if ( task.requires ) {
						return !! get( this, task.requires );
					}

					return true;
				} );
			};
		},
		availableTasks() {
			return this.getTasksByAssignment( 'deck' );
		},
		completed() {
			return this.availableTasks.length === 0;
		},
	},
	watch: {
		availableTasks( list ) {
			if ( list && list[ 0 ] ) {
				if ( list[ 0 ].category !== this.deckPopup.id ) {
					this.deckPopup = CATEGORIES.find( c => c.id === list[ 0 ].category );
					this.showDeckPopup = true;
				}
			} else {
				this.showDeckPopup = false;
			}
		},
		completed( completed ) {
			if ( completed && ! this.seenReviewInfo ) {
				this.showModal( 'review' );
				this.setSeenReviewInfo( true );
			}
			if ( ! completed ) {
				this.filter = CATEGORIES.map( c => c.id );
			}
		},
	},
	mounted() {
		// If no tasks have been assigned, show category popup
		if ( this.assignmentHistory.length === 0 ) {
			this.deckPopup = CATEGORIES.find( c => c.id === this.availableTasks[ 0 ].category );
			this.showDeckPopup = true;
		}

		if ( this.completed ) {
			this.setSeenReviewInfo( true );
		}
	},
	methods: {
		...mapMutations( [
			'undoAssignTask',
			'setSeenReviewInfo',
		] ),

		highlightColumn( card ) {
			const { dragX, dragY } = card;

			let match = this.$refs.dropzones.find( d => d.isPointInBounds( dragX, dragY ) );
			if ( ! match ) {
				match = this.$refs.naDropzone.isPointInBounds( dragX, dragY ) ? this.$refs.naDropzone : null;
			}

			this.activeDropzone = match ? match.name : null;
		},
		assignTask( task, assignment ) {
			this.$store.commit( 'assignTask', {
				task,
				assignment,
			} );
		},
		moveCardToDropzone( card ) {
			if ( this.activeDropzone ) {
				this.assignTask( card.subject, this.activeDropzone );
				this.activeDropzone = null;
			}
			card.reset();
		},
		quickAddTask( dropzone ) {
			const task = this.availableTasks[ 0 ];
			if ( task ) {
				this.assignTask( task, dropzone );
			}
		},
	},
};
</script>
