<template>
	<div class="session">
		<div class="session__info">
			<h3 class="session__title">
				{{ title }}
			</h3>
			<p class="session__meta">
				{{ date }}
				&nbsp;&bull;&nbsp;
				<strong>{{ status }}</strong>
			</p>
		</div>
		<div class="session__actions">
			<AppButton icon="pencil" color="yellow" icon-only @click="$emit( 'select', id, resumeScreen )">
				{{ isComplete ? 'Edit' : 'Resume' }}
			</AppButton>
			<AppButton v-if="isComplete" icon="chart" color="pink" icon-only @click="$emit( 'select', id, 'stats' )">
				Summary
			</AppButton>
			<AppButton icon="trashcan" color="black" icon-only @click="confirmDelete">
				Delete
			</AppButton>
		</div>
	</div>
</template>

<script>
import dateFormat from 'dateformat';
import TASKS from '../store/tasks.json';
import AppButton from './AppButton.vue';

export default {
	components: { AppButton },
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			required: true,
		},
		profile1: {
			type: Object,
			default: null,
		},
		profile2: {
			type: Object,
			default: null,
		},
		assignmentHistory: {
			type: Array,
			default: null,
		},
		lastUpdate: {
			type: Number,
			default: Date.now(),
		},
	},
	computed: {
		title() {
			if ( ! this.profile1 ) {
				return 'Unnamed Session';
			}

			return `${ this.profile1.name || 'Participant 1' } & ${ this.profile2.name || 'Participant 2' }`;
		},
		isComplete() {
			return this.assignmentHistory?.length === TASKS.length;
		},
		resumeScreen() {
			return this.assignmentHistory?.length ? 'tasks' : 'profiles';
		},
		date() {
			return dateFormat( this.lastUpdate * 1000, 'mmmm d, yyyy - h:MMtt' );
		},
		status() {
			if ( this.isComplete ) {
				return 'Completed';
			}

			if ( this.assignmentHistory?.length > 0 ) {
				return 'In Progress';
			}

			if ( this.profile1?.name !== 'Participant 1' || this.profile2?.name !== 'Participant 2' ) {
				return 'Configured';
			}

			return 'Not Started';
		},
	},
	methods: {
		confirmDelete() {
			/* eslint-disable no-alert */
			if ( confirm( `Are you sure you want to delete the session "${ this.title }"?` ) ) {
				this.$emit( 'delete', this.id );
			}
		},
	},
};
</script>
