<template>
	<div class="demographics-group">
		<h3>{{ profile.name }}</h3>
		<div class="form__field">
			<label for="gender" class="form__field__title">What is your gender identity?</label>
			<AppDropdown
				id="gender"
				v-model="gender"
				placeholder="Select"
				:options="[
					'Woman',
					'Man',
					'Non-binary',
					'Two-Spirit',
					'Transgender',
					'Cisgender',
					'Trans masculine',
					'Trans feminine',
					'Trans non-binary',
					'Agender',
					'Genderqueer',
					'Genderfluid',
					'Intersex',
					'Other',
				]"
				multiple
			/>
		</div>
		<div v-if="gender && gender.includes( 'Other' )" class="form__field is-subfield">
			<label for="gender_detail" class="form__field__title">Please Specify</label>
			<input id="gender_detail" v-model="genderDetail" type="text" required />
		</div>
		<div class="form__field">
			<label for="pronouns" class="form__field__title">What are your pronouns?</label>
			<AppDropdown
				id="pronouns"
				v-model="pronouns"
				placeholder="Select"
				:options="[
					'he/him',
					'she/her',
					'they/them',
					'other',
				]"
				multiple
			/>
		</div>
		<div v-if="pronouns && pronouns.includes( 'other' )" class="form__field is-subfield">
			<label for="pronouns_detail" class="form__field__title">Please Specify</label>
			<input id="pronouns_detail" v-model="pronounsDetail" type="text" required />
		</div>
		<div class="form__field">
			<label for="ethnicity" class="form__field__title">Race/Ethnicity</label>
			<input id="ethnicity" v-model="ethnicity" type="text" required />
		</div>
		<div class="form__field">
			<label for="annual_income" class="form__field__title">Current annual income (individual) before taxes</label>
			<AppDropdown
				id="annual_income"
				v-model="annualIncome"
				placeholder="Select"
				:options="[
					'Under $CAD 10,000',
					'$10,000 to less than $25,000',
					'$25,000 to less than $40,000',
					'$40,000 to less than $50,000',
					'$50,000 to less than $60,000',
					'$60,000 to less than $75,000',
					'$75,000 to less than $100,000',
					'$100,000 to less than $110,000',
					'$110,000 to less than $120,000',
					'$120,000 to less than $150,000',
					'$150,000 to less than $200,000',
					'$200,000 to less than $250,000',
					'$250,000 to less than $500,000',
					'Over $500,000',
					'Prefer not to say',
				]"
			/>
		</div>
		<div class="form__field">
			<label for="living_area" class="form__field__title">Area that you currently live</label>
			<AppDropdown
				id="living_area"
				v-model="livingArea"
				placeholder="Select"
				:options="[
					'Large city (200,000 or more residents)',
					'City (50,000-199,999 residents)',
					'Town or suburb (5,000-49,999 residents) ',
					'Rural area (less than 5,000 residents)',
				]"
			/>
		</div>
	</div>
</template>

<script>
import AppDropdown from '../components/AppDropdown.vue';

export default {
	components: {
		AppDropdown,
	},

	props: {
		profile: {
			type: Object,
			required: true,
		},
	},

	computed: {
		gender: {
			get() {
				return this.profile.gender;
			},
			set( newValue ) {
				this.$emit( 'update', { gender: newValue } );
			},
		},
		genderDetail: {
			get() {
				return this.profile.genderDetail;
			},
			set( newValue ) {
				this.$emit( 'update', { genderDetail: newValue } );
			},
		},
		pronouns: {
			get() {
				return this.profile.pronouns;
			},
			set( newValue ) {
				this.$emit( 'update', { pronouns: newValue } );
			},
		},
		pronounsDetail: {
			get() {
				return this.profile.pronounsDetail;
			},
			set( newValue ) {
				this.$emit( 'update', { pronounsDetail: newValue } );
			},
		},
		ethnicity: {
			get() {
				return this.profile.ethnicity;
			},
			set( newValue ) {
				this.$emit( 'update', { ethnicity: newValue } );
			},
		},
		annualIncome: {
			get() {
				return this.profile.annualIncome;
			},
			set( newValue ) {
				this.$emit( 'update', { annualIncome: newValue } );
			},
		},
		livingArea: {
			get() {
				return this.profile.livingArea;
			},
			set( newValue ) {
				this.$emit( 'update', { livingArea: newValue } );
			},
		},
	},
}
</script>
