<template>
	<AppScreen name="advanced" sidebar-name="Sessions">
		<template #header>
			<div class="screen__header__logo">
				<img src="../assets/img/logo.svg" alt="" />
			</div>
		</template>

		<template #content>
			<h1>(Household) Care/Work Portrait</h1>
			<AppButton v-if="allowNewSessions" color="black" @click="newSession">
				Start a new session
			</AppButton>
			<div v-else class="button-group is-style-vertical">
				<AppButton color="black" :route="{ name: lastScreen }">
					{{ lastScreen === 'intro' ? 'Begin' : 'Continue' }} your session
				</AppButton>
				<AppButton is-plain @click="showModal( 'login' )">
					or log in to create a new one
				</AppButton>
			</div>
		</template>

		<template #footer>
			<img src="../assets/img/sshr.svg" alt="" />
		</template>

		<template v-if="sessionList.length > 0" #sidebar>
			<h2>Past Sessions</h2>
			<SessionItem
				v-for="session in sessionList"
				:id="session.id"
				:key="session.id"
				v-bind="session.data"
				:last-update="session.lastUpdate"
				@select="loadSession"
				@delete="deleteSession"
			/>
		</template>
	</AppScreen>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AppScreen from '../components/AppScreen.vue';
import AppButton from '../components/AppButton.vue';
import SessionItem from '../components/SessionItem.vue';

export default {
	components: {
		AppScreen,
		AppButton,
		SessionItem,
	},
	computed: mapState( [ 'sessionList', 'allowNewSessions', 'lastScreen' ] ),
	methods: {
		...mapActions( [ 'startSession', 'useSession', 'deleteSession' ] ),

		loadSession( id, screen ) {
			this.useSession( id )
				.then( () => this.$router.push( { name: screen } ) );
		},

		newSession() {
			this.startSession()
				.then( () => this.$router.push( { name: 'consent' } ) );
		},
	},
};
</script>
