import Vue from 'vue';
import Vuex from 'vuex';
import { fromPairs, nth } from 'lodash';

import API from './api';
import { REQUESTED_USER, REQUESTED_SESSION } from './requested';

import ASSIGNMENTS from './assignments.json';
import TASKS from './tasks.json';

Vue.use( Vuex );

const AUTOSAVE_INTERVAL = 5000;

const FACES = [ 'm1', 'm2', 'm3', 'm4', 'm5', 'm6', 'w1', 'w2', 'w3', 'w4', 'w5', 'w6' ];
const ANIMALS = [ 'pig', 'lion', 'rabbit', 'hippo', 'sheep', 'cow', 'chicken', 'fox' ];
const BABY_ANIMALS = [ 'baby-pig', 'baby-cow', 'baby-panda', 'baby-elephant', 'baby-hippo', 'baby-lion' ];
const COLORS = [ 'black', 'brown', 'blue', 'pink', 'yellow', 'green', 'orange' ];

export { FACES, ANIMALS, BABY_ANIMALS, COLORS };

const TASK_SLOTS = [ 'deck', ...ASSIGNMENTS.map( a => a.id ) ];

if ( REQUESTED_USER ) {
	sessionStorage.setItem( 'authToken', window.location.hash.substring( 2 ) );
}
if ( REQUESTED_SESSION ) {
	sessionStorage.setItem( 'lastSession', window.location.hash.substring( 2 ) );
}

function randomAvatar() {
	return {
		face: ANIMALS[ Math.floor( Math.random() * ANIMALS.length ) ],
		color: COLORS[ Math.floor( Math.random() * COLORS.length ) ],
	};
}

function blankProfile( number, name ) {
	return {
		name: name || `Participant ${ number || 'name' }`,
		...randomAvatar(),
		type: null,
		birthYear: null,
		gender: [],
		genderDetail: null,
		pronouns: [],
		pronounsDetail: null,
		minority: null,
		indigenous: null,
		identity: [],
		ethnicity: null,
		annualIncome: null,
		livingArea: null,
	};
}

function blankSession() {
	return {
		profile1: blankProfile( 1 ),
		profile2: blankProfile( 2 ),
		children: [],
		otherPeople: [],
		assignedTasks: {},
		assignmentHistory: [],
	};
}

const sessionUpdates = new Map();
function updateSession( state, property ) {
	sessionUpdates.set( property, state[ property ] );
}

function doAssignTask( state, { task, assignment, position = -1 } ) {
	// Get current assignment/position
	const prevAssignment = state.assignedTasks[ task.id ] || 'deck';
	const prevPosition = state.tasksByAssignment[ prevAssignment ].indexOf( task );
	if ( prevPosition >= 0 ) {
		state.tasksByAssignment[ prevAssignment ].splice( prevPosition, 1 );
	}

	// Add to new assignment at the specified position
	if ( position < 0 ) {
		state.tasksByAssignment[ assignment ].push( task );
	} else {
		state.tasksByAssignment[ assignment ].splice( position, 0, task );
	}

	// Update assignment in task map
	state.assignedTasks = {
		...state.assignedTasks,
		[ task.id ]: assignment,
	};
	updateSession( state, 'assignedTasks' );

	return [ task.id, prevAssignment, prevPosition ];
}

const store = new Vuex.Store( {
	state: {
		activeModals: [],
		seenTasksInfo: false,
		seenReviewInfo: false,

		authToken: sessionStorage.getItem( 'authToken' ),
		allowNewSessions: false,
		sessionId: sessionStorage.getItem( 'lastSession' ),
		sessionList: [],

		profile1: {},
		profile2: {},
		children: [],
		otherPeople: [],
		supports: [],
		assignedTasks: {},
		assignmentHistory: [],
		tasksByAssignment: {},
		feedback: '',
		lastScreen: 'intro',
	},
	getters: {
		authHeader: ( state ) => {
			return {
				'X-RCW-Token': state.authToken,
			};
		},

		sessionData: ( state ) => {
			return {
				profile1: state.profile1,
				profile2: state.profile2,
				children: state.children,
				otherPeople: state.otherPeople,
				supports: state.supports,
				assignedTasks: state.assignedTasks,
				assignmentHistory: state.assignmentHistory,
				feedback: state.feedback,
				lastScreen: state.lastScreen,
			};
		},

		assignmentMeta: ( { profile1, profile2 } ) => {
			return {
				'all-p1': {
					label: profile1.name,
					avatar: {
						face: profile1.face,
						color: profile1.color,
					},
				},
				'all-p2': {
					label: profile2.name,
					avatar: {
						face: profile2.face,
						color: profile2.color,
					},
				},

				'mostly-p1': {
					label: `Mostly ${ profile1.name }`,
					avatar: {
						faces: [ profile1.face, profile2.face ],
						colors: [ profile1.color, profile2.color ],
						isStacked: true,
					},
				},
				'mostly-p2': {
					label: `Mostly ${ profile2.name }`,
					avatar: {
						faces: [ profile2.face, profile1.face ],
						colors: [ profile2.color, profile1.color ],
						isStacked: true,
					},
				},

				'shared': {
					avatar: {
						faces: [ profile1.face, profile2.face ],
						colors: [ profile1.color, profile2.color ],
					},
				},
			};
		},

		assignments: ( state, getters ) => {
			return ASSIGNMENTS.map( ( assignment ) => {
				return {
					...assignment,
					...getters.assignmentMeta[ assignment.id ],
					tasks: getters.getTasksForAssignment( assignment.id ),
				};
			} );
		},

		getTasksForAssignment: state => ( group ) => {
			return state.tasksByAssignment[ group ] || [];
		},
	},
	mutations: {
		setAuthToken( state, token ) {
			state.authToken = token;
			if ( token ) {
				sessionStorage.setItem( 'authToken', token );
			} else {
				sessionStorage.removeItem( 'authToken' );
			}
		},
		setAllowNewSessions( state, allow ) {
			state.allowNewSessions = !! allow;
		},
		setSessionList( state, sessions ) {
			state.sessionList = sessions;
		},

		setSessionId( state, id ) {
			state.sessionId = id;

			if ( id ) {
				sessionStorage.setItem( 'lastSession', id );
			} else {
				sessionStorage.removeItem( 'lastSession' );
			}
		},
		setSessionData( state, data = {} ) {
			state.profile1 = {
				...blankProfile( 1 ),
				...data.profile1,
			};
			state.profile2 = {
				...blankProfile( 2 ),
				...data.profile2,
			};
			state.children = data.children || [];
			state.otherPeople = data.otherPeople || [];
			state.supports = data.supports || [];
			state.assignedTasks = data.assignedTasks || {};
			state.assignmentHistory = data.assignmentHistory || [];
			state.feedback = data.feedback || '';
			state.lastScreen = data.lastScreen || 'intro';

			state.tasksByAssignment = fromPairs( TASK_SLOTS.map( ( slot ) => {
				return [
					slot,
					TASKS.filter( ( t ) => {
						const assignment = state.assignedTasks[ t.id ] || 'deck';
						return assignment === slot;
					} ),
				];
			} ) );

			state.seenTasksInfo = false;
			state.seenReviewInfo = false;
		},

		showModal( state, name ) {
			if ( ! state.activeModals.includes( name ) ) {
				state.activeModals.push( name );
			}
		},
		hideModal( state, name ) {
			const activeModals = [ ...state.activeModals ];
			const index = activeModals.indexOf( name );
			if ( index >= 0 ) {
				activeModals.splice( index, 1 );
				state.activeModals = activeModals;
			}
		},

		setSeenTasksInfo( state, value ) {
			state.seenTasksInfo = value;
		},
		setSeenReviewInfo( state, value ) {
			state.seenReviewInfo = value;
		},

		updateProfile( state, { id, ...data } ) {
			state[ id ] = {
				...state[ id ],
				...data,
			};
			updateSession( state, id );
		},

		updateChild( state, { id, ...data } ) {
			const children = [ ...state.children ];
			children[ id ] = {
				...( children[ id ] || {} ),
				...data,
			};
			state.children = children;
			updateSession( state, 'children' );
		},

		addChild( state ) {
			state.children = [
				...state.children,
				{
					name: 'Child',
					...randomAvatar(),
					birthYear: null,
					birthMonth: null,
					pronouns: [],
					pronounsDetail: null,
				},
			];
			updateSession( state, 'children' );
		},

		removeChild( state, index ) {
			const children = [ ...state.children ];
			children.splice( index, 1 );
			state.children = children;
			updateSession( state, 'children' );
		},

		updateOtherPeople( state, people ) {
			state.otherPeople = people;
			updateSession( state, 'otherPeople' );
		},

		updateSupports( state, supports ) {
			state.supports = supports;
			updateSession( state, 'supports' );
		},

		updateFeedback( state, screen ) {
			state.feedback = screen;
			updateSession( state, 'feedback' );
		},

		updateLastScreen( state, screen ) {
			state.lastScreen = screen;
			updateSession( state, 'lastScreen' );
		},

		assignTask( state, payload ) {
			// Perform the change
			const prevState = doAssignTask( state, payload );

			// Record it's previous placement
			state.assignmentHistory = [
				...state.assignmentHistory,
				prevState,
			];
			updateSession( state, 'assignmentHistory' );
		},

		undoAssignTask( state ) {
			// Get the previous state, re-assign to that
			const [ id, assignment, position ] = nth( state.assignmentHistory, -1 );
			state.assignmentHistory = state.assignmentHistory.slice( 0, -1 );
			updateSession( state, 'assignmentHistory' );

			// Get the task by ID
			const task = TASKS.find( t => t.id === id );
			if ( ! task ) {
				return;
			}

			// Perform the reassignment
			doAssignTask( state, {
				task,
				assignment,
				position,
			} );
		},
	},
	actions: {
		async authenticate( { state, getters, commit }, credentials ) {
			let result;
			if ( typeof credentials === 'object' ) {
				result = await API.post( 'login', credentials );
			} else {
				result = await API.get( 'verify', null, getters.authHeader );
			}

			// If requesting a session, check if it's the requested one
			if ( result.session && REQUESTED_SESSION && result.session !== state.sessionId ) {
				throw {
					code: 'invalid_account',
					message: 'You are not authorized for this session',
				};
			}

			commit( 'setAllowNewSessions', result.allow_new );

			if ( result.token ) {
				commit( 'setAuthToken', result.token );
			}
			if ( result.sessions ) {
				commit( 'setSessionList', result.sessions );
			}
			if ( result.session ) {
				commit( 'setSessionId', result.session );
			}
		},
		async listSessions( { getters, commit } ) {
			const { results = [] } = await API.get( 'sessions', null, getters.authHeader );
			commit( 'setSessionList', results );
		},
		async login( { state, dispatch }, credentials ) {
			await dispatch( 'authenticate', credentials );

			try {
				await dispatch( 'useSession', state.sessionId );
			} catch ( error ) {
				throw {
					code: 'empty_session',
					message: 'No active session found',
				};
			}
		},
		async initSession( { commit }, data ) {
			data = data || blankSession();
			commit( 'setSessionData', data );

			return data;
		},
		async saveSession( { state, commit, getters }, data ) {
			const session = await API.post( 'sessions', data, getters.authHeader );
			commit( 'setSessionId', session.id );
			commit( 'setSessionList', [
				session,
				...state.sessionList,
			] );
		},
		async startSession( { dispatch }, data ) {
			return dispatch( 'saveSession', await dispatch( 'initSession', data ) );
		},
		async useSession( { state, commit, getters }, sessionId ) {
			if ( ! sessionId ) {
				throw {
					code: 'empty_session',
					message: 'No active session found',
				};
			}

			const session = state.sessionList.find( s => s.id === sessionId );
			if ( session ) {
				commit( 'setSessionId', session.id );
				commit( 'setSessionData', session.data );
			} else {
				const { id, data } = await API.get( `sessions/${ sessionId }`, null, getters.authHeader );
				commit( 'setSessionId', id );
				commit( 'setSessionData', data );
			}
		},
		async deleteSession( { state, commit, getters }, sessionId ) {
			commit( 'setSessionList', state.sessionList.filter( s => s.id !== sessionId ) );
			await API.delete( `sessions/${ sessionId }`, null, getters.authHeader );
		},
	},
	modules: {
	},
} );

// General error handling
function handleError( error ) {
	// If authentication fails, clear auth token
	if ( error.code === 'rest_forbidden' ) {
		console.log( 'Error processing request, need to log in again' );
		store.commit( 'setAuthToken', null );
	}
}

// When any action, errors, use the error handler
store.subscribeAction( {
	error: ( action, state, error ) => handleError( error ),
} );

// Save the current session, creating a new one if needed
async function saveSession( updates ) {
	try {
		return await API.put( `sessions/${ store.state.sessionId }`, updates, store.getters.authHeader );
	} catch ( error ) {
		if ( error.code !== 'rest_session_invalid_id' ) {
			throw error;
		}

		store.dispatch( 'saveSession', store.getters.sessionData );
		store.dispatch( 'listSessions' );
	}
}

// Send pending updates regularly as needed
let updateInProgress = false;
async function saveSessionUpdates() {
	setTimeout( saveSessionUpdates, AUTOSAVE_INTERVAL );

	// Stagger updates to minimize load, and don't bother if no session id or auth token are set
	if ( updateInProgress || ! store.state.sessionId || ! store.state.authToken || sessionUpdates.size === 0 ) {
		return;
	}

	updateInProgress = true;
	const updates = Object.fromEntries( sessionUpdates.entries() );

	try {
		await saveSession( updates );
		sessionUpdates.clear();
		updateInProgress = false;
	} catch ( error ) {
		updateInProgress = false;
		handleError( error );
	}
}

saveSessionUpdates();

export default store;
