import Vue from 'vue';
const API_URL = process.env.VUE_APP_API_URL;

const API = new Vue( {
	methods: {
		async call( endpoint, method, data = null, headers = {} ) {
			this.$emit( 'request', { endpoint, method, data, headers } );

			let body = null;
			if ( data ) {
				if ( method === 'GET' ) {
					const params = new URLSearchParams( data );
					endpoint += '?' + params.toString();
				} else {
					headers[ 'content-type' ] = 'application/json';
					body = JSON.stringify( data );
				}
			}

			const args = {
				method,
				headers,
				body,
			};

			const response = await fetch( API_URL + endpoint, args );
			const responseData = await response.json();

			if ( responseData.code ) {
				this.$emit( 'error', responseData );
				throw responseData;
			}

			this.$emit( 'response', responseData );
			return responseData;
		},

		get( endpoint, data = null, headers = {} ) {
			return this.call( endpoint, 'GET', data, headers );
		},
		post( endpoint, data, headers = {} ) {
			return this.call( endpoint, 'POST', data, headers );
		},
		put( endpoint, data, headers = {} ) {
			return this.call( endpoint, 'PUT', data, headers );
		},
		delete( endpoint, data = null, headers = {} ) {
			return this.call( endpoint, 'DELETE', data, headers );
		},
	},
} );

export default API;
