<template>
	<section
		:class="{
			'screen': true,
			[ name ]: true,
			'with-sidebar': $slots.sidebar,
			'show-sidebar': showSidebar,
		}"
	>
		<header v-if="$slots.header" class="screen__header">
			<slot name="header" />
		</header>
		<div v-if="$slots.content" class="screen__content page-content">
			<slot name="content" />
		</div>
		<div v-if="$slots.sidebar" class="screen__sidebar">
			<slot name="sidebar" />
		</div>
		<div v-if="$slots.footer" class="screen__footer">
			<slot name="footer" />
		</div>
		<slot />
		<AppButton v-if="$slots.sidebar" color="black" class="screen__sidebar-toggle" @click="showSidebar = ! showSidebar">
			{{ showSidebar ? 'Hide' : 'Show' }} {{ sidebarName }}
		</AppButton>
	</section>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
	components: {
		AppButton,
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		sidebarName: {
			type: String,
			default: 'Options',
		},
	},
	data() {
		return {
			showSidebar: false,
		};
	},
};
</script>
