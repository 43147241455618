<template>
	<AppModal name="demographics" class="demographics-modal" is-jumbo>
		<h2>Additional Demographics</h2>

		<DemographicFields
			:profile="profile1"
			@update="updateProfile1"
		/>

		<DemographicFields
			:profile="profile2"
			@update="updateProfile2"
		/>

		<AppButton color="black" @click="hideModal( 'demographics' )">
			Save Answers
		</AppButton>
	</AppModal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import AppModal from '../components/AppModal.vue';
import AppButton from '../components/AppButton.vue';
import DemographicFields from '../components/DemographicFields.vue';

export default {
	components: {
		AppModal,
		AppButton,
		DemographicFields,
	},
	computed: {
		...mapState( [ 'profile1', 'profile2' ] ),
	},
	methods: {
		...mapMutations( [ 'updateProfile' ] ),

		updateProfile1( changes ) {
			this.updateProfile( {
				id: 'profile1',
				...changes,
			} );
		},
		updateProfile2( changes ) {
			this.updateProfile( {
				id: 'profile2',
				...changes,
			} );
		},
	},
};
</script>
