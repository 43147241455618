var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"Component",class:( _obj = {
		'button': ! _vm.iconOnly && ! _vm.isPlain,
		'icon-button': _vm.iconOnly && ! _vm.isPlain,
		'has-icon': !! _vm.icon,
		'is-style-square': _vm.isSquare,
		'is-style-keyline': _vm.isKeyline,
		'is-style-small': _vm.isSmall,
		'is-style-inverse': _vm.isInverse
	}, _obj[ ("is-color-" + _vm.color) ] = _vm.color, _obj )},'Component',_vm.attrs,false),_vm.$listeners),[(_vm.iconOnly)?_c('ScreenReaderText',[_vm._t("default")],2):_c('span',{staticClass:"button-text"},[_vm._t("default")],2),(_vm.icon)?_c('AppIcon',{attrs:{"type":_vm.icon}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }