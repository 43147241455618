<template>
	<AppScreen name="stats">
		<template #content>
			<h1>Summary</h1>

			<ProfileSummary title="Profile Information" />

			<TaskBreakdownSummary title="Tasks and Responsibilities By Assignment" />

			<div class="button-group">
				<AppButton icon="pencil" color="black" @click="showModal( 'feedback' )">
					Leave Feedback
				</AppButton>
				<AppButton icon="plus" color="black" @click="showModal( 'demographics' )">
					Add Demographics
				</AppButton>
			</div>
		</template>

		<FeedbackModal />
		<DemographicsModal />
	</AppScreen>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import AppScreen from '../components/AppScreen.vue';
import AppButton from '../components/AppButton.vue';
import ProfileSummary from '../components/ProfileSummary.vue';
import TaskBreakdownSummary from '../components/TaskBreakdownSummary.vue';
import FeedbackModal from '../components/FeedbackModal.vue';
import DemographicsModal from '../components/DemographicsModal.vue';

export default {
	components: {
		AppScreen,
		AppButton,
		ProfileSummary,
		TaskBreakdownSummary,
		FeedbackModal,
		DemographicsModal,
	},
	computed: {
		...mapState( [ 'profile1', 'profile2' ] ),

		feedback: {
			get() {
				return this.$store.state.feedback || '';
			},
			set( newValue ) {
				this.$store.commit( 'updateFeedback', newValue );
			},
		},
	},
	methods: {
		...mapMutations( [ 'updateProfile' ] ),

		updateProfile1( changes ) {
			this.updateProfile( {
				id: 'profile1',
				...changes,
			} );
		},
		updateProfile2( changes ) {
			this.updateProfile( {
				id: 'profile2',
				...changes,
			} );
		},
	},
};
</script>

<style lang="scss">
.feedback-modal {
	text-align: center;

	textarea {
		display: block;
		width: 100%;
		padding: 1em;
		border-radius: 8px;
		border: 1px solid;
		margin: 2em 0;
	}
}
</style>
