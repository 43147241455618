var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScreen',{attrs:{"name":"tasks"}},[_c('div',{staticClass:"task-columns"},_vm._l((_vm.columnDropzones),function(zone){return _c('Dropzone',{key:zone.id,ref:"dropzones",refInFor:true,class:{
				'task-column': true,
				'is-active': _vm.activeDropzone === zone.id,
			},attrs:{"name":zone.id}},[_c('button',{staticClass:"task-column__header",attrs:{"disabled":_vm.completed},on:{"click":function($event){return _vm.quickAddTask( zone.id )}}},[_c('ProfileCard',_vm._b({attrs:{"name":zone.label}},'ProfileCard',zone.avatar,false)),_c('span',{staticClass:"task-counter"},[(_vm.getTasksByAssignment( zone.id ).length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.getTasksByAssignment( zone.id ).length)+" ")]):_c('AppIcon',{attrs:{"type":"plus"}})],1)],1),_c('TransitionGroup',{staticClass:"task-list task-column__content",attrs:{"tag":"div","type":"animation"}},_vm._l((_vm.getTasksByAssignment( zone.id )),function(item){return _c('Draggable',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter.includes( item.category )),expression:"filter.includes( item.category )"}],key:item.id,attrs:{"subject":item,"target":zone.id,"enabled":! _vm.completed},on:{"dragging":_vm.highlightColumn,"dragEnd":_vm.moveCardToDropzone}},[_c('TaskItem',_vm._b({},'TaskItem',item,false))],1)}),1)],1)}),1),_c('div',{staticClass:"task-footer-wrapper"},[_c('div',{staticClass:"task-footer"},[(! _vm.completed)?_c('div',{staticClass:"task-deck-spacer"}):_c('button',{class:{
					'filter-toggle': true,
					'is-active': _vm.showFilter,
				},on:{"click":function($event){_vm.showFilter = ! _vm.showFilter}}},[_vm._v(" Filter ")]),_c('div',{staticClass:"task-buckets"},_vm._l((_vm.bucketDropzones),function(zone){return _c('Dropzone',{key:zone.id,ref:"dropzones",refInFor:true,class:{
						'task-dropzone': true,
						'is-active': _vm.activeDropzone === zone.id,
					},attrs:{"name":zone.id}},[_c('button',{staticClass:"task-dropzone__button",on:{"click":function($event){return _vm.quickAddTask( zone.id )}}},[_c('ProfileCard',{attrs:{"name":zone.label}}),_c('span',{staticClass:"task-counter"},[(_vm.getTasksByAssignment( zone.id ).length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.getTasksByAssignment( zone.id ).length)+" ")]):_c('AppIcon',{attrs:{"type":"plus"}})],1)],1)])}),1),_c('div',{staticClass:"task-buttons"},[_c('AppButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.assignmentHistory.length > 0),expression:"assignmentHistory.length > 0"}],staticClass:"undo-button",attrs:{"color":"grey","icon":"undo","is-small":_vm.completed},on:{"click":_vm.undoAssignTask}},[_vm._v(" Undo ")]),_c('Dropzone',{ref:"naDropzone",class:{
						'is-active': _vm.activeDropzone === 'none',
					},attrs:{"name":"none","component":_vm.AppButton,"color":"brown-light","icon":"trashcan","is-small":_vm.completed},on:{"click":function($event){return _vm.quickAddTask( 'none' )}}},[_vm._v(" Not Applicable ")]),(_vm.completed)?_c('AppButton',{staticClass:"next-button",attrs:{"route":"/supports2","color":"pink"}},[_vm._v(" Finish ")]):_vm._e()],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}],staticClass:"filter-flyout"},[_c('p',[_vm._v("Filter Tasks & Responsibilities")]),_c('FilterToggles',{attrs:{"options":_vm.$options.CATEGORIES},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('AppButton',{attrs:{"color":"black","is-small":""},on:{"click":function($event){_vm.showFilter = false}}},[_vm._v(" Close ")])],1),_c('div',{staticClass:"task-deck-wrapper"},[_c('Transition',[(! _vm.completed)?_c('TransitionGroup',{staticClass:"task-deck",attrs:{"tag":"div","type":"animation"}},_vm._l((_vm.availableTasks),function(item,k){return _c('Draggable',{key:item.id,attrs:{"subject":item,"target":_vm.activeDropzone,"enabled":k === 0},on:{"dragging":_vm.highlightColumn,"dragEnd":_vm.moveCardToDropzone}},[_c('TaskItem',_vm._b({attrs:{"is-card":""}},'TaskItem',item,false))],1)}),1):_vm._e()],1),_c('Transition',{attrs:{"mode":"out-in"}},[(_vm.deckPopup.id && _vm.showDeckPopup)?_c('div',{key:_vm.deckPopup.id || 'none',staticClass:"task-deck-popover"},[_c('p',[_vm._v(_vm._s(_vm.deckPopup.label))]),_c('p',[(_vm.deckPopup.hasTasks)?_c('span',{class:( _obj = {
							'category-label': true
						}, _obj[("is-color-" + (_vm.deckPopup.color))] = _vm.deckPopup.color, _obj )},[_vm._v(" Tasks ")]):_vm._e(),_vm._v("   "),(_vm.deckPopup.hasDuties)?_c('span',{class:( _obj$1 = {
							'category-label': true
						}, _obj$1[("is-color-" + (_vm.deckPopup.color) + "-light")] = _vm.deckPopup.color, _obj$1 )},[_vm._v(" Responsibilities ")]):_vm._e()]),_c('AppButton',{attrs:{"color":"grey","is-small":""},on:{"click":function($event){_vm.showDeckPopup = false}}},[_vm._v(" Close ")])],1):_vm._e()])],1),_c('AppModal',{staticClass:"help-modal",attrs:{"name":"review","is-jumbo":""}},[_c('AvatarGraphic',_vm._b({},'AvatarGraphic',_vm.sharedDropzone.avatar,false)),_c('h2',[_vm._v("Thank you!")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }