<template>
	<Component
		:is="tag"
		:class="{
			'button': ! iconOnly && ! isPlain,
			'icon-button': iconOnly && ! isPlain,
			'has-icon': !! icon,
			'is-style-square': isSquare,
			'is-style-keyline': isKeyline,
			'is-style-small': isSmall,
			'is-style-inverse': isInverse,
			[ `is-color-${ color }` ]: color,
		}"
		v-bind="attrs"
		v-on="$listeners"
	>
		<ScreenReaderText v-if="iconOnly">
			<slot />
		</ScreenReaderText>
		<span v-else class="button-text">
			<slot />
		</span>

		<AppIcon v-if="icon" :type="icon" />
	</Component>
</template>

<script>
import AppIcon from './AppIcon.vue';
import ScreenReaderText from './ScreenReaderText.vue';

export default {
	components: {
		AppIcon,
		ScreenReaderText,
	},
	inheritAttrs: false,
	props: {
		color: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		href: {
			type: String,
			default: null,
		},
		route: {
			type: [ String, Object ],
			default: null,
		},
		newTab: Boolean,
		iconOnly: Boolean,
		isPlain: Boolean,
		isSquare: Boolean,
		isKeyline: Boolean,
		isSmall: Boolean,
		isInverse: Boolean,
	},
	computed: {
		tag() {
			if ( this.route ) {
				return 'RouterLink';
			}

			if ( this.href ) {
				return 'a';
			}

			return 'button';
		},
		attrs() {
			if ( this.route ) {
				return { to: this.route };
			}

			if ( this.href ) {
				return {
					href: this.href,
					target: this.newTab ? '_blank' : undefined,
				};
			}

			return {};
		},
	},
};
</script>
