<template>
	<svg
		:class="{
			'chart': true,
			'has-active-slice': activeSlice !== null,
		}"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${ width } ${ height }`"
		:style="{
			'--scale': (1 / (1 - padding)),
		}"
	>
		<g :transform="`translate(${ width / 2 }, ${ height / 2 })`">
			<path
				v-for="( data, index ) in datum"
				v-show="data.value > 0"
				:key="data.label"
				v-bind="getSlice( index )"
				@mouseenter="$emit( 'focus', index )"
				@mouseleave="$emit( 'blur', index )"
				@click="$emit( 'focus', index )"
			/>
		</g>
	</svg>
</template>

<script>
import { arc, pie } from 'd3-shape';

const unsortedPie = pie().value( d => d.value ).sort( null );
const baseArc = arc().innerRadius( 0 );

export default {
	emits: [ 'focus', 'blur' ],
	inheritAttrs: false,
	props: {
		width: {
			type: Number,
			default: 200,
		},
		height: {
			type: Number,
			default: 200,
		},
		radius: {
			type: Number,
			default: 0,
		},
		padding: {
			type: Number,
			default: 0,
		},
		datum: {
			type: Array,
			required: true,
		},
		activeSlice: {
			type: Number,
			default: null,
		},
	},
	computed: {
		pieData() {
			return unsortedPie( this.datum );
		},
		pieRadius() {
			return ( ( this.radius || ( Math.min( this.width, this.height ) / 2 ) ) * ( 1 - this.padding ) ) - 2;
		},
		pieArc() {
			return baseArc.outerRadius( this.pieRadius );
		},
	},
	methods: {
		getSlice( index ) {
			const data = this.datum[ index ];

			let fillColor = null;
			let colorClass = null;

			if ( data.color.indexOf( '#' ) === 0 ) {
				fillColor = data.color;
			} else {
				colorClass = data.color;
			}

			return {
				class: {
					'chart__slice': true,
					'is-active': index === this.activeSlice,
					[ `is-color-${ colorClass }` ]: colorClass,
				},
				fill: fillColor,
				d: this.pieArc( this.pieData[ index ] ),
			};
		},
	},
};
</script>
