<template>
	<div class="filter-options">
		<button
			:class="{
				'category-label': true,
				'is-color-black': true,
				'is-keyline': ! showingAll
			}"
			@click="toggleOption( 'all' )"
		>
			Show All
		</button>
		<button
			v-for="option in options"
			:key="option.id"
			:class="{
				'category-label': true,
				[`is-color-${ option.color }`]: option.color,
				'is-keyline': showingAll || ! checked.includes( option.id )
			}"
			@click="toggleOption( option.id )"
		>
			{{ option.label }}
		</button>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	model: {
		prop: 'checked',
		event: 'update',
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		checked: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		allValues() {
			return this.options.map( o => o.id );
		},
		showingAll() {
			return this.checked.length === this.options.length;
		},
	},
	methods: {
		toggleOption( value ) {
			if ( value === 'all' ) {
				this.$emit( 'update', this.allValues );
				return;
			}

			if ( this.showingAll ) {
				this.$emit( 'update', [ value ] );
				return;
			}

			const change = ! this.checked.includes( value );

			const result = new Set( this.checked );
			result[ change ? 'add' : 'delete' ]( value );

			if ( result.size === 0 ) {
				this.$emit( 'update', [ ...this.allValues ] );
			} else {
				this.$emit( 'update', [ ...result.values() ] );
			}
		},
	},
}
</script>
