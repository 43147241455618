<template>
	<Transition>
		<div
			v-show="isOpen"
			:class="{
				'modal': true,
				'is-mini': isMini,
				'is-jumbo': isJumbo,
			}"
			tabindex="-1"
			:style="{ zIndex }"
		>
			<div class="modal__frame">
				<slot />
				<AppButton
					v-if="! noCloseButton"
					class="modal__close"
					@click="hideModal( name )"
				>
					Close
				</AppButton>
			</div>
		</div>
	</Transition>
</template>

<script>
import { mapState } from 'vuex';
import AppButton from './AppButton.vue';

const MODAL_ZINDEX = 10;

export default {
	components: { AppButton },
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		isMini: Boolean,
		isJumbo: Boolean,
		noCloseButton: Boolean,
	},
	computed: mapState( {
		isOpen( state ) {
			return state.activeModals.includes( this.name );
		},
		zIndex( state ) {
			return MODAL_ZINDEX + state.activeModals.indexOf( this.name );
		},
	} ),
	watch: {
		isOpen( isOpen ) {
			if ( isOpen ) {
				this.$nextTick( () => this.$el.focus() );
			} else {
				this.$el.blur();
			}
		},
	},
};
</script>
