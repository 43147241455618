var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demographics-group"},[_c('h3',[_vm._v(_vm._s(_vm.profile.name))]),_c('div',{staticClass:"form__field"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"gender"}},[_vm._v("What is your gender identity?")]),_c('AppDropdown',{attrs:{"id":"gender","placeholder":"Select","options":[
				'Woman',
				'Man',
				'Non-binary',
				'Two-Spirit',
				'Transgender',
				'Cisgender',
				'Trans masculine',
				'Trans feminine',
				'Trans non-binary',
				'Agender',
				'Genderqueer',
				'Genderfluid',
				'Intersex',
				'Other' ],"multiple":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),(_vm.gender && _vm.gender.includes( 'Other' ))?_c('div',{staticClass:"form__field is-subfield"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"gender_detail"}},[_vm._v("Please Specify")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.genderDetail),expression:"genderDetail"}],attrs:{"id":"gender_detail","type":"text","required":""},domProps:{"value":(_vm.genderDetail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.genderDetail=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"form__field"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"pronouns"}},[_vm._v("What are your pronouns?")]),_c('AppDropdown',{attrs:{"id":"pronouns","placeholder":"Select","options":[
				'he/him',
				'she/her',
				'they/them',
				'other' ],"multiple":""},model:{value:(_vm.pronouns),callback:function ($$v) {_vm.pronouns=$$v},expression:"pronouns"}})],1),(_vm.pronouns && _vm.pronouns.includes( 'other' ))?_c('div',{staticClass:"form__field is-subfield"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"pronouns_detail"}},[_vm._v("Please Specify")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pronounsDetail),expression:"pronounsDetail"}],attrs:{"id":"pronouns_detail","type":"text","required":""},domProps:{"value":(_vm.pronounsDetail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pronounsDetail=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"form__field"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"ethnicity"}},[_vm._v("Race/Ethnicity")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ethnicity),expression:"ethnicity"}],attrs:{"id":"ethnicity","type":"text","required":""},domProps:{"value":(_vm.ethnicity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ethnicity=$event.target.value}}})]),_c('div',{staticClass:"form__field"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"annual_income"}},[_vm._v("Current annual income (individual) before taxes")]),_c('AppDropdown',{attrs:{"id":"annual_income","placeholder":"Select","options":[
				'Under $CAD 10,000',
				'$10,000 to less than $25,000',
				'$25,000 to less than $40,000',
				'$40,000 to less than $50,000',
				'$50,000 to less than $60,000',
				'$60,000 to less than $75,000',
				'$75,000 to less than $100,000',
				'$100,000 to less than $110,000',
				'$110,000 to less than $120,000',
				'$120,000 to less than $150,000',
				'$150,000 to less than $200,000',
				'$200,000 to less than $250,000',
				'$250,000 to less than $500,000',
				'Over $500,000',
				'Prefer not to say' ]},model:{value:(_vm.annualIncome),callback:function ($$v) {_vm.annualIncome=$$v},expression:"annualIncome"}})],1),_c('div',{staticClass:"form__field"},[_c('label',{staticClass:"form__field__title",attrs:{"for":"living_area"}},[_vm._v("Area that you currently live")]),_c('AppDropdown',{attrs:{"id":"living_area","placeholder":"Select","options":[
				'Large city (200,000 or more residents)',
				'City (50,000-199,999 residents)',
				'Town or suburb (5,000-49,999 residents) ',
				'Rural area (less than 5,000 residents)' ]},model:{value:(_vm.livingArea),callback:function ($$v) {_vm.livingArea=$$v},expression:"livingArea"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }