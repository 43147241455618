<template>
	<AppModal name="feedback" class="feedback-modal" is-jumbo>
		<h2>Feedback</h2>
		<p>Do you have any feedback about using this tool? Perhaps suggestions for tasks that you didn't see listed?</p>
		<textarea v-model="feedback" rows="10" maxlength="1000" />
		<AppButton color="black" @click="hideModal( 'feedback' )">
			Save Feedback
		</AppButton>
	</AppModal>
</template>

<script>
import AppModal from '../components/AppModal.vue';
import AppButton from '../components/AppButton.vue';

export default {
	components: {
		AppModal,
		AppButton,
	},
	computed: {
		feedback: {
			get() {
				return this.$store.state.feedback || '';
			},
			set( newValue ) {
				this.$store.commit( 'updateFeedback', newValue );
			},
		},
	},
};
</script>
