<template>
	<AppScreen name="intro">
		<template #content>
			<div class="intro-slide">
				<h2>Before we begin...</h2>
				<p>Let's review the interview consent form</p>
				<p>Do you have any questions?</p>
				<P>Do you both consent to participate?</p>
			</div>
		</template>

		<template #footer>
			<AppButton route="/" color="black">
				Back
			</AppButton>
			<AppButton route="/tutorial" color="pink">
				Next
			</AppButton>
		</template>
	</AppScreen>
</template>

<script>
import AppScreen from '../components/AppScreen';
import AppButton from '../components/AppButton.vue';

export default {
	components: {
		AppScreen,
		AppButton,
	},
};
</script>
