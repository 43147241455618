<template>
	<div
		:class="{
			'chart-card': true,
			'is-style-jumbo': isJumbo,
		}"
	>
		<div class="chart-card__header">
			<ProfileCard
				v-if="avatar"
				v-bind="avatar"
				:name="label"
				class="chart-card__label"
			/>
			<h3
				v-else-if="label"
				class="chart-card__label"
			>
				{{ label }}
			</h3>

			<div class="chart-card__chart">
				<PieChart
					:width="400"
					:height="400"
					:padding=".1"
					:datum="datum"
					:active-slice="highlighted"
					@focus="highlighted = $event"
					@blur="highlighted = null"
				/>
			</div>
		</div>
		<div class="chart-card__body">
			<dl
				:class="{
					'chart-card__legend': true,
					'has-active-item': highlighted !== null,
				}"
			>
				<div
					v-for="(data, index) in datum"
					ref="labels"
					:key="data.label"
					:class="{
						'chart-card__legend__item': true,
						[ `is-color-${ data.color }` ]: true,
						'is-enabled': data.value > 0,
						'is-active': index === highlighted,
					}"
					@mouseover="setHighlighted( index )"
					@mouseleave="unsetHighlighted( index )"
					@click="setHighlighted( index )"
				>
					<dt class="chart-card__legend__label">
						<ProfileCard
							v-if="data.avatar"
							v-bind="data.avatar"
							:name="data.label"
						/>
						<span v-else>
							{{ data.label }}
						</span>
					</dt>
					<dd class="chart-card__legend__value">
						{{ getDataPortion( data.value ) }}%
					</dd>
				</div>
			</dl>
		</div>
	</div>
</template>

<script>
import ProfileCard from './ProfileCard.vue';
import PieChart from './PieChart';

export default {
	components: {
		ProfileCard,
		PieChart,
	},
	inheritAttrs: false,
	props: {
		avatar: {
			type: Object,
			default: null,
		},
		label: {
			type: String,
			default: '',
		},
		datum: {
			type: Array,
			default: () => [],
		},
		isJumbo: Boolean,
	},
	data() {
		return {
			highlighted: null,
		};
	},
	computed: {
		datumTotal() {
			return this.datum.reduce( ( total, d ) => total + d.value, 0 );
		},
	},
	methods: {
		getDataPortion( value ) {
			return this.datumTotal && Math.round( ( value / this.datumTotal ) * 100 );
		},
		setHighlighted( index ) {
			if ( this.datum[ index ].value > 0 ) {
				this.highlighted = index;
			}
		},
		unsetHighlighted( index ) {
			if ( this.highlighted === index ) {
				this.highlighted = null;
			}
		}
	},
};
</script>
