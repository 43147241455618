<template>
	<div class="profile">
		<AvatarGraphic v-bind="$attrs" />
		<div class="profile__label">
			<h3 class="profile__name">
				{{ name }}
			</h3>
			<slot />
		</div>
	</div>
</template>

<script>
import AvatarGraphic from './AvatarGraphic';

export default {
	components: {
		AvatarGraphic,
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			default: null,
		},
	},
};
</script>
