<template>
	<AppScreen name="stats">
		<template #content>
			<h1>Portrait Stats Breakdown</h1>

			<ProfileSummary title="Profile Information" />

			<OverallTaskStats title="Overall Tasks and Responsibilities" />

			<ParticipantTaskStats title="Each Person" />

			<TaskBreakdownSummary title="Tasks and Responsibilities By Assignment" />

			<div class="button-group">
				<AppButton icon="pencil" color="black" @click="showModal( 'feedback' )">
					Leave Feedback
				</AppButton>
				<AppButton icon="plus" color="black" @click="showModal( 'demographics' )">
					Add Demographics
				</AppButton>
			</div>
		</template>
	</AppScreen>
</template>

<script>
import AppScreen from '../components/AppScreen.vue';
import ProfileSummary from '../components/ProfileSummary.vue';
import OverallTaskStats from '../components/OverallTaskStats.vue';
import ParticipantTaskStats from '../components/ParticipantTaskStats.vue';
import TaskBreakdownSummary from '../components/TaskBreakdownSummary.vue';

export default {
	components: {
		AppScreen,
		ProfileSummary,
		OverallTaskStats,
		ParticipantTaskStats,
		TaskBreakdownSummary,
	},
};
</script>
