var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"Component",class:( _obj = {
		'draggable': true,
		'is-dragging': _vm.dragging
	}, _obj[("over-target-" + _vm.target)] = _vm.enabled, _obj ),style:({
		top: _vm.dragY && _vm.dragY + 'px',
		left: _vm.dragX && _vm.dragX + 'px',
	}),on:{"mousedown":_vm.enableDrag,"touchstart":_vm.enableDrag}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }