<template>
	<div class="screen-section">
		<div class="section-header">
			<h2>{{ title }}</h2>
			<AppButton v-if="edit" :route="edit" icon="pencil" is-small>
				Edit
			</AppButton>
		</div>
		<slot />
	</div>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
	components: {
		AppButton,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		edit: {
			type: String,
			default: null,
		},
	},
};
</script>
