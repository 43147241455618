<template>
	<VSelect
		:components="{ Deselect, OpenIndicator }"
		:value="value"
		v-bind="$attrs"
		@input="$emit( 'update', $event )"
	>
		<template
			v-for="( _, slot ) of $scopedSlots"
			v-slot:[slot]="scope"
		>
			<slot :name="slot" v-bind="scope" />
		</template>
	</VSelect>
</template>

<script>
import VSelect from 'vue-select';

const Deselect = {
	render: createElement => createElement( 'span', 'Clear' ),
};

const OpenIndicator = {
	render: createElement => createElement( 'span', 'Open/Close' ),
};

export default {
	components: {
		VSelect,
	},
	inheritAttrs: false,
	model: {
		prop: 'value',
		event: 'update',
	},
	props: {
		value: {
			type: [ String, Number, Array ],
			default: null,
		},
	},
	data() {
		return {
			Deselect,
			OpenIndicator,
		};
	},
};
</script>
