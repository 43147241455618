<template>
	<AppScreenSection :title="title" edit="/tasks">
		<ChartCard
			:datum="datumOverall"
			is-jumbo
		/>
	</AppScreenSection>
</template>

<script>
import { mapGetters } from 'vuex';
import { fromPairs, forOwn } from 'lodash';
import CATEGORIES from '../store/categories.json';
import AppScreenSection from '../components/AppScreenSection.vue';
import ChartCard from '../components/ChartCard.vue';

const categoriesById = fromPairs( CATEGORIES.map( category => [ category.id, category.hasStats ] ) );

export default {
	components: {
		AppScreenSection,
		ChartCard,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters( [ 'assignments', 'getTasksForAssignment' ] ),

		datumOverall() {
			const colors = [ '#f5a800', '#fffbf4', '#fad482', '#ffffff', '#c8ac89', '#e0e2e4', '#9e9e9e' ];
			const datum = [];

			forOwn( this.assignments, ( { id, label, avatar, hasStats } ) => {
				if ( hasStats ) {
					const tasks = this.getTasksForAssignment( id ).filter( task => categoriesById[ task.category ] );

					datum.push( {
						label,
						avatar,
						color: colors.shift(),
						value: tasks.length,
					} );
				}
			} );

			return datum;
		},
	},
};
</script>
