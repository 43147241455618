<script>
const PATHS = {
	check: {
		width: 40,
		height: 40,
		path: 'M16,33.7L0.9,18.6c-1.2-1.2-1.2-3.1,0-4.2c1.2-1.2,3.1-1.2,4.2,0L16,25.3L34.9,6.4c1.2-1.2,3.1-1.2,4.2,0c1.2,1.2,1.2,3.1,0,4.2L16,33.7z'
	},
	close: {
		width: 24,
		height: 24,
		path: 'M20.3,17.5c0.8,0.8,0.8,2,0,2.8c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6L12,14.8l-5.5,5.5c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8L9.2,12L3.7,6.5c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0L12,9.2l5.5-5.5c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8L14.8,12L20.3,17.5z',
	},
	down: {
		width: 24,
		height: 24,
		path: 'M14.6,18.8c-0.8,1.4-2.7,1.9-4.1,1.1c-0.5-0.3-0.8-0.6-1.1-1.1L3.8,9.1C2.9,7.7,3.4,5.8,4.9,5c0.5-0.3,1-0.4,1.5-0.4h11.2c1.7,0,3,1.3,3,3c0,0.5-0.1,1.1-0.4,1.5L14.6,18.8z',
	},
	pencil: {
		width: 24,
		height: 24,
		path: 'M7,24H1a1,1,0,0,1-.71-.29A1,1,0,0,1,0,23V17a1,1,0,0,1,.07-.38,1.06,1.06,0,0,1,.22-.33l16-16A1,1,0,0,1,17,0a1,1,0,0,1,.71.29l6,6a1,1,0,0,1,0,1.41l-16,16A1,1,0,0,1,7,24ZM2,22H6.59l15-15L17,2.41l-15,15ZM20,11a1.07,1.07,0,0,1-.39-.07,1,1,0,0,1-.32-.22l-6-6A1,1,0,0,1,13.06,4a1,1,0,0,1,.29-.67,1,1,0,0,1,1.36-.05l6,6a1,1,0,0,1,.21,1.08,1.06,1.06,0,0,1-.36.45A1,1,0,0,1,20,11Z'
	},
	plus: {
		width: 30,
		height: 30,
		path: 'M25,13h-8V5c0-1.1-0.9-2-2-2s-2,0.9-2,2v8H5c-1.1,0-2,0.9-2,2s0.9,2,2,2h8v8c0,1.1,0.9,2,2,2s2-0.9,2-2v-8h8c1.1,0,2-0.9,2-2S26.1,13,25,13z'
	},
	trashcan: {
		width: 28,
		height: 31,
		path: 'M8.8,23.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-8.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v8.6c0,0.3,0.1,0.6,0.3,0.8C8.3,23.4,8.5,23.6,8.8,23.6zM14,23.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8v-8.6c0-0.3-0.1-0.6-0.3-0.8s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v8.6c0,0.3,0.1,0.6,0.3,0.8C13.4,23.4,13.7,23.6,14,23.6z M19.2,23.6c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8v-8.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v8.6c0,0.3,0.1,0.6,0.3,0.8C18.6,23.4,18.9,23.6,19.2,23.6z M26.9,5.2H20l-0.9-3C19,1.6,18.6,1,18,0.6C17.5,0.2,16.8,0,16.2,0h-4.3c-0.7,0-1.3,0.2-1.9,0.6C9.4,1,9,1.6,8.8,2.2L8,5.2H1.1c-0.3,0-0.6,0.1-0.8,0.3C0.1,5.7,0,6,0,6.3S0.1,6.8,0.3,7c0.2,0.2,0.5,0.3,0.8,0.3h1.3V28c0,0.8,0.3,1.5,0.9,2.1C3.8,30.7,4.5,31,5.3,31h17.4c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1V7.3h1.2c0.3,0,0.6-0.1,0.8-0.3C27.9,6.8,28,6.5,28,6.2c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C27.2,5.2,27.1,5.2,26.9,5.2z M10.9,2.9c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.2h4.3c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.3,0.5l0.7,2.3h-7.6L10.9,2.9z M23.5,28.3L23.5,28.3c-0.1,0.2-0.2,0.3-0.4,0.4s-0.3,0.2-0.5,0.2H5.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6V7.3h19V28.3z',
	},
	undo: {
		width: 42,
		height: 41,
		path: 'M20.654 40.9639C17.6521 40.9672 14.6864 40.3059 11.9692 39.0272C9.25196 37.7485 6.85021 35.8842 4.93565 33.5673C4.80839 33.4009 4.74402 33.1948 4.75393 32.9854C4.76384 32.776 4.84739 32.5769 4.98978 32.4233L8.63148 28.7447C8.8175 28.5881 9.05286 28.5025 9.29585 28.5031C9.41391 28.5124 9.52885 28.5456 9.63374 28.6007C9.73862 28.6558 9.83127 28.7316 9.90608 28.8236C11.1657 30.482 12.7917 31.8255 14.6565 32.7486C16.5213 33.6718 18.5741 34.1495 20.654 34.1443C22.873 34.1471 25.059 33.6065 27.0218 32.5696C28.9847 31.5327 30.6649 30.0308 31.9163 28.1947C33.1676 26.3587 33.9522 24.244 34.2018 22.0347C34.4514 19.8254 34.1584 17.5885 33.3483 15.5186C32.5382 13.4487 31.2356 11.6085 29.5537 10.1582C27.8718 8.7079 25.8618 7.69138 23.6983 7.19711C21.5349 6.70285 19.2838 6.74582 17.1407 7.32228C14.9976 7.89874 13.0276 8.99123 11.4021 10.5047L15.0438 14.1833C15.2863 14.4148 15.4521 14.7152 15.519 15.0441C15.5859 15.3729 15.5506 15.7144 15.4179 16.0226C15.2918 16.3369 15.0752 16.6066 14.7956 16.7972C14.516 16.9877 14.1861 17.0906 13.848 17.0926H1.95339C1.72848 17.0939 1.50553 17.0508 1.29725 16.9658C1.08898 16.8807 0.899456 16.7554 0.739513 16.597C0.57957 16.4385 0.452337 16.2501 0.365079 16.0424C0.27782 15.8347 0.232246 15.6118 0.230957 15.3864V3.44831C0.232568 3.11007 0.334481 2.77994 0.523749 2.49986C0.713018 2.21978 0.9811 2.00239 1.29394 1.8753C1.60146 1.74232 1.94227 1.70695 2.27046 1.77396C2.59865 1.84096 2.89848 2.00712 3.12956 2.25006L6.57441 5.70182C10.3811 2.09307 15.4137 0.0678889 20.654 0.0360107C26.0706 0.0360107 31.2653 2.19203 35.0953 6.02977C38.9254 9.8675 41.0771 15.0726 41.0771 20.5C41.0771 25.9274 38.9254 31.1324 35.0953 34.9702C31.2653 38.8079 26.0706 40.9639 20.654 40.9639Z',
	},
	chart: {
		width: 612,
		height: 612,
		path: 'M337.8,0c-9.9,0-18,8.2-18,18v255.9c0,9.9,8.2,18,18,18h255.9c9.9,0,18-8.1,18-18c0-72.6-28.9-142.3-80.2-193.7C480.2,28.8,410.5,0,337.8,0z M355.9,36.7c56.5,4.3,109.8,28.6,150.2,69c40.3,40.3,64.6,93.7,68.9,150.2H355.9V36.7z M273.9,64c-72.6,0-142.3,28.8-193.7,80.2C28.8,195.6,0,265.2,0,337.8s28.8,142.3,80.2,193.7c51.4,51.4,121,80.2,193.7,80.2s142.3-28.8,193.7-80.2c51.3-51.3,80.2-121,80.2-193.7c0-9.9-8.2-18-18-18H291.9V81.9C291.9,72.1,283.7,64,273.9,64z M255.9,100.7v237.1c0,9.9,8.1,18,18,18H511c-4.3,56.5-28.6,109.9-69,150.2c-44.6,44.6-105.1,69.7-168.2,69.7S150.3,550.7,105.7,506C61,461.4,36,400.9,36,337.8s25-123.6,69.7-168.2C146,129.3,199.4,105,255.9,100.7z',
	},
};

export default {
	functional: true,
	inheritAttrs: false,
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	render( createElement, { props } ) {
		const {
			width = 16,
			height = 16,
			path,
		} = PATHS[ props.type ] || {};

		return createElement( 'svg', {
			class: `icon icon--${ props.type }`,
			attrs: {
				'xmlns': 'http://www.w3.org/2000/svg',
				'viewBox': `0 0 ${ width } ${ height }`,
				'aria-hidden': true,
				width,
				height,
			},
		}, [
			createElement( 'path', { attrs: { d: path } } ),
		] );
	},
};
</script>
