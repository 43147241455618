var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-options"},[_c('button',{class:{
			'category-label': true,
			'is-color-black': true,
			'is-keyline': ! _vm.showingAll
		},on:{"click":function($event){return _vm.toggleOption( 'all' )}}},[_vm._v(" Show All ")]),_vm._l((_vm.options),function(option){
		var _obj;
return _c('button',{key:option.id,class:( _obj = {
			'category-label': true
		}, _obj[("is-color-" + (option.color))] = option.color, _obj['is-keyline'] =  _vm.showingAll || ! _vm.checked.includes( option.id ), _obj ),on:{"click":function($event){return _vm.toggleOption( option.id )}}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }