<template>
	<AppScreenSection :title="title" edit="/tasks">
		<ul class="category-list">
			<li
				v-for="cat in categories"
				:key="cat.id"
				:class="[ 'category-list__item', `is-color-${ cat.color }` ]"
			>
				{{ cat.label }}
			</li>
		</ul>
		<TaskBreakdown
			v-for="group in assignmentSummaries"
			:key="group.id"
			v-bind="group"
		/>
	</AppScreenSection>
</template>

<script>
import { mapGetters } from 'vuex';
import CATEGORIES from '../store/categories.json';
import AppScreenSection from '../components/AppScreenSection.vue';
import TaskBreakdown from '../components/TaskBreakdown.vue';

export default {
	components: {
		AppScreenSection,
		TaskBreakdown,
	},
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			categories: CATEGORIES,
		};
	},
	computed: {
		...mapGetters( [ 'assignments' ] ),

		assignmentSummaries() {
			return this.assignments.filter( d => d.hasBreakdown );
		},
	},
};
</script>
