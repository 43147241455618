<template>
	<Component :is="component" v-bind="$attrs">
		<slot />
	</Component>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		component: {
			type: [ String, Function, Object ],
			default: 'div',
		},
	},
	methods: {
		isPointInBounds( x, y ) {
			const el = this.$el;
			return ( x >= el.offsetLeft && x <= el.offsetLeft + el.offsetWidth ) &&
				( y >= el.offsetTop && y <= el.offsetTop + el.offsetHeight );
		},
	},
};
</script>
