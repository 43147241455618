<template functional>
	<Component :is="tag" class="screen-reader-text">
		<slot />
	</Component>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		tag: {
			type: String,
			default: 'span',
		},
	},
};
</script>
