<template>
	<Component
		:is="tag"
		:class="{
			'draggable': true,
			'is-dragging': dragging,
			[`over-target-${ target }`]: enabled,
		}"
		:style="{
			top: dragY && dragY + 'px',
			left: dragX && dragX + 'px',
		}"
		@mousedown="enableDrag"
		@touchstart="enableDrag"
	>
		<slot />
	</Component>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		tag: {
			type: [ String, Object ],
			default: 'div',
		},
		subject: {
			type: Object,
			default: null,
		},
		target: {
			type: String,
			default: null,
		},
		enabled: {
			type: Boolean,
			default: true,
		},
	},
	emits: [ 'dragStart', 'dragging', 'dragEnd' ],
	data() {
		return {
			dragging: false,
			dragX: undefined,
			dragY: undefined,
		};
	},
	computed: {
		onTop() {
			return this.index === 0;
		},
	},
	mounted() {
		this.method = null;
		this.moveEvent = null;
		this.endEvent = null;
		this.onHandleDrag = this.handleDrag.bind( this );
		this.onFinishDrag = this.finishDrag.bind( this );
	},
	methods: {
		setPosition( event ) {
			if ( typeof TouchEvent !== 'undefined' && event instanceof TouchEvent ) {
				event = event.touches[ 0 ];
			}

			const { pageX, pageY } = event;
			this.dragX = pageX;
			this.dragY = pageY;
		},
		enableDrag( event ) {
			// Ensure this is enabled
			if ( ! this.enabled ) {
				return;
			}

			// Skip if method is already set
			if ( this.method ) {
				return;
			}

			// Skip if target was a button within
			if ( event.target.nodeName === 'BUTTON' ) {
				return;
			}

			// Record the input method
			this.method = event.type === 'touchstart' ? 'touch' : 'mouse';

			this.dragging = true;
			this.setPosition( event );

			this.moveEvent = 'mousemove';
			this.endEvent = 'mouseup';
			if ( event.type === 'touchstart' ) {
				this.moveEvent = 'touchmove';
				this.endEvent = 'touchend';
			}

			window.addEventListener( this.moveEvent, this.onHandleDrag );
			window.addEventListener( this.endEvent, this.onFinishDrag );

			this.$emit( 'dragStart', this );
		},
		handleDrag( event ) {
			// Ignore if dragging not properly initiatied
			if ( ! this.dragging ) {
				return;
			}

			this.setPosition( event );

			this.$emit( 'dragging', this );
		},
		finishDrag() {
			window.removeEventListener( this.moveEvent, this.onHandleDrag );
			window.removeEventListener( this.endEvent, this.onFinishDrag );

			this.$emit( 'dragEnd', this );

			// Delay unsetting method, so fallback mouse event doesn't trigger
			setTimeout( () => this.method = null );
		},
		reset() {
			this.dragX = this.dragY = undefined;
			this.dragging = false;
		},
	},
};
</script>

<style lang="scss">
.draggable {
	position: relative;
	transform-origin: center;

	&.is-dragging {
		position: fixed;
		transform: translateX(-50%) translateY(-50%);
	}

	@keyframes draggable-appear {
		from {
			transform: translateX(-50%) translateY(-50%) scale(0);
			opacity: 0;
		}
	}
	&.v-enter-active {
		animation: draggable-appear .2s ease-in;
	}

	@keyframes draggable-vanish {
		to {
			transform: translateX(-50%) translateY(-50%) scale(0);
			opacity: 0;
		}
	}
	&.v-leave-active {
		animation: draggable-vanish .2s ease-in;
	}
}
</style>
