<template>
	<div
		v-if="boxes"
		:class="{
			'avatar-graphic': true,
			'is-style-half': boxes.length > 1 && ! isStacked,
			'is-style-stacked': boxes.length > 1 && isStacked,
		}"
	>
		<div
			v-for="(box, index) in boxes"
			:key="index"
			:class="[ 'avatar-graphic__box', `is-sprite-${ sprite }`, `is-color-${ box.color }` ]"
		>
			<svg class="avatar-graphic__graphic">
				<use :xlink:href="`${ require( `../assets/img/${ sprite }.svg` ) }#${ box.face }`" />
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		face: {
			type: String,
			default: null,
		},
		faces: {
			type: Array,
			default: null,
		},
		color: {
			type: String,
			default: null,
		},
		colors: {
			type: Array,
			default: null,
		},
		isStacked: Boolean,
	},
	computed: {
		sprite() {
			const face = this.faces ? this.faces[ 0 ] : this.face;
			return face.match( /[mw]\d/ ) ? 'faces' : 'animals';
		},
		boxes() {
			let boxes;
			if ( this.face ) {
				boxes = [ {
					face: this.face,
					color: this.color,
				} ];
			} else
			if ( this.faces ) {
				boxes = this.faces.map( ( face, i ) => ( {
					face,
					color: this.colors[ i ] || this.colors[ 0 ] || this.color,
				} ) );
			}

			return boxes;
		},
	},
};
</script>
