<template>
	<AppScreen name="profiles">
		<template #content>
			<div class="columns">
				<div class="column">
					<h1>Survey Participants</h1>
					<div class="profile-editor">
						<AvatarGraphic
							:face="profile1.face"
							:color="profile1.color"
						/>
						<AppButton
							class="edit-button"
							icon="pencil"
							is-square
							@click="editProfile( 'profile1' )"
						>
							{{ profile1.name }}
						</AppButton>
					</div>
					<div class="profile-editor">
						<AvatarGraphic
							:face="profile2.face"
							:color="profile2.color"
						/>
						<AppButton
							class="edit-button"
							icon="pencil"
							is-square
							@click="editProfile( 'profile2' )"
						>
							{{ profile2.name }}
						</AppButton>
					</div>
				</div>
				<div class="column">
					<h1>Household</h1>
					<div v-for="(child, index) in children" :key="index" class="profile-editor is-small">
						<AvatarGraphic
							:face="child.face"
							:color="child.color"
						/>
						<AppButton
							class="edit-button"
							icon="pencil"
							is-square
							@click="editChild( index )"
						>
							{{ child.name }}
						</AppButton>
					</div>
					<AppButton v-if="children.length < 10" icon="plus" @click="newChild">
						Add Child
					</AppButton>
					<hr />
					<div v-for="(person, index) in otherPeople" :key="index" class="minifield">
						<label :for="`person${ index + 1 }`" class="screen-reader-text">Describe Person</label>
						<input
							:id="`person${ index + 1 }`"
							type="text"
							:value="person"
							class="is-style-small"
							placeholder="Describe person"
							@input="updatePerson( index, $event.target.value )"
						/>
						<AppButton icon="trashcan" icon-only @click="deletePerson( index )">
							Remove Person
						</AppButton>
					</div>
					<AppButton v-if="otherPeople.length < 10" icon="plus" @click="addPerson">
						Add Person
					</AppButton>
				</div>
			</div>
		</template>

		<template #footer>
			<AppButton route="/tutorial" color="black">
				Back
			</AppButton>
			<AppButton route="/supports" color="pink">
				Next
			</AppButton>
		</template>

		<AppModal name="profile">
			<div class="avatar-editor" @click="showModal( 'avatar' )">
				<AvatarGraphic
					:face="subject.face"
					:color="subject.color"
				/>
				<AppButton icon="pencil" is-plain icon-only>
					Edit Avatar
				</AppButton>
			</div>
			<div class="form__field editable">
				<label for="participant_name">Name</label>
				<input id="participant_name" v-model="subject.name" type="text" required />
				<p><em>For privacy, we only ask for a first name or a pseudonym.</em></p>
			</div>
			<div class="form__field">
				<label for="birthYear" class="form__field__title">What is your year of birth?</label>
				<input id="birthYear" v-model="subject.birthYear" type="number" min="1900" :max="$options.CURRENT_YEAR" required />
			</div>
			<AppButton color="black" @click="hideModal( 'profile' )">
				Save Changes
			</AppButton>
		</AppModal>

		<AppModal name="child">
			<div class="avatar-editor" @click="showModal( 'avatar' )">
				<AvatarGraphic
					:face="subject.face"
					:color="subject.color"
				/>
				<AppButton icon="pencil" is-plain icon-only>
					Edit Avatar
				</AppButton>
			</div>
			<div class="form__field editable">
				<label for="child_name">Name</label>
				<input id="child_name" v-model="subject.name" type="text" required />
				<p><em>For privacy, we only ask for a first name or a pseudonym.</em></p>
			</div>
			<div class="form__field">
				<label for="child_birth_year">Birth Year</label>
				<input id="birthYear" v-model="subject.birthYear" type="number" min="1900" :max="$options.CURRENT_YEAR" required />
			</div>
			<div class="form__field">
				<label for="child_birth_month">Birth Month</label>
				<AppDropdown
					id="child_birth_month"
					v-model="subject.birthMonth"
					placeholder="Select"
					:options="[
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'September',
						'October',
						'November',
						'December',
					]"
				/>
			</div>
			<div class="button-group spaced">
				<AppButton color="black" @click="hideModal( 'child' )">
					Save Changes
				</AppButton>
				<AppButton color="pink" @click="dropChild( subject.id )">
					Remove Child
				</AppButton>
			</div>
		</AppModal>

		<AppModal name="avatar" is-mini>
			<h4>Choose Avatar</h4>
			<div :class="[ 'avatar-picker', `is-color-${ subject.color }` ]">
				<label
					v-for="avatar in avatars"
					:key="avatar"
					class="avatar-picker__option"
				>
					<input v-model="subject.face" type="radio" name="avatar" :value="avatar" />
					<svg class="avatar-picker__graphic">
						<use :xlink:href="`${ require( '../assets/img/animals.svg' ) }#${ avatar }`" />
					</svg>
				</label>
			</div>
			<h4>Choose a Color</h4>
			<div class="color-picker">
				<label
					v-for="color in colors"
					:key="color"
					class="color-picker__option"
				>
					<input v-model="subject.color" type="radio" name="color" :value="color" />
					<div class="color-picker__shape" />
				</label>
			</div>
			<AppButton color="black" @click="hideModal( 'avatar' )">
				Save Changes
			</AppButton>
		</AppModal>
	</AppScreen>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ANIMALS, BABY_ANIMALS, COLORS } from '../store';
import AppScreen from '../components/AppScreen.vue';
import AppButton from '../components/AppButton.vue';
import AppDropdown from '../components/AppDropdown.vue';
import AvatarGraphic from '../components/AvatarGraphic';
import AppModal from '../components/AppModal';

const CURRENT_YEAR = ( new Date() ).getUTCFullYear();

export default {
	CURRENT_YEAR,

	components: {
		AppScreen,
		AppButton,
		AppDropdown,
		AvatarGraphic,
		AppModal,
	},
	data() {
		return {
			colors: COLORS,
			subject: {},
		};
	},
	computed: {
		...mapState( [ 'profile1', 'profile2', 'children', 'otherPeople' ] ),

		avatars() {
			return typeof this.subject.id === 'string' ? ANIMALS : BABY_ANIMALS;
		},
	},
	watch: {
		subject: {
			handler( newProfile, oldProfile ) {
				// only update when it's the same profile that's changing
				if ( newProfile.id === oldProfile.id ) {
					if ( typeof newProfile.id === 'string' ) {
						this.updateProfile( newProfile );
					} else {
						this.updateChild( newProfile );
					}
				}
			},
			deep: true,
		},
	},
	methods: {
		...mapMutations( [ 'updateProfile', 'updateChild', 'addChild', 'removeChild', 'updateOtherPeople' ] ),

		editProfile( profileId ) {
			this.subject = {
				id: profileId,
				...this[ profileId ],
			};
			this.showModal( 'profile' );
		},
		editChild( index ) {
			this.subject = {
				id: index,
				...this.children[ index ],
			};
			this.showModal( 'child' );
		},

		newChild() {
			this.addChild();
			this.editChild( this.children.length - 1 );
		},
		dropChild( index ) {
			this.hideModal( 'child' );
			this.removeChild( index );
		},

		addPerson() {
			this.updateOtherPeople( [ ...this.otherPeople, '' ] );
		},
		updatePerson( index, value ) {
			const people = [ ...this.otherPeople ];
			people[ index ] = value;
			this.updateOtherPeople( people );
		},
		deletePerson( index ) {
			const people = [ ...this.otherPeople ];
			people.splice( index, 1 );
			this.updateOtherPeople( people );
		},
	},
};
</script>
