<template>
	<AppScreen name="supports">
		<template #content>
			<h1>Who and what provides support to your care/work lives?</h1>
			<div class="columns">
				<div class="column">
					<div class="form__field">
						<label
							v-for="option in $options.SUPPORTS"
							:key="option"
							class="checkbox"
						>
							<input v-model="mainSupports" type="checkbox" :value="option" />
							{{ option }}
						</label>
					</div>
				</div>
				<div class="column">
					<div v-for="(support, index) in otherSupports" :key="index" class="minifield">
						<label :for="`support${ index + 1 }`" class="screen-reader-text">Describe Support</label>
						<input
							:id="`support${ index + 1 }`"
							type="text"
							:value="support"
							class="is-style-small"
							placeholder="Describe support"
							@input="updateSupport( index, $event.target.value )"
						/>
						<AppButton icon="trashcan" icon-only @click="deleteSupport( index )">
							Remove Support
						</AppButton>
					</div>
					<AppButton v-if="otherSupports.length < 10" icon="plus" @click="addSupport">
						Add Support
					</AppButton>
				</div>
			</div>
		</template>

		<template #footer>
			<AppButton :route="back" color="black">
				Back
			</AppButton>
			<AppButton :route="next" color="pink">
				Next
			</AppButton>
		</template>
	</AppScreen>
</template>

<script>
import AppScreen from '../components/AppScreen.vue';
import AppButton from '../components/AppButton.vue';
import { mapMutations, mapState } from 'vuex';

const SUPPORTS = [
	'Extended family or friends (e.g. grandparents, chosen family)',
	'Daycare center, preschool, or childcare center (CPE*)',
	'Family/home daycare',
	'Nanny or sitter',
	'After school care',
	'Caregiver (i.e. for late life care or disabilities)',
	'Spiritual or religious supports',
	'Communities or groups',
	'Other household support (e.g., cleaner)',
	'Other mental health support',
	'Food banks / community fridges',
	'Food delivery (e.g. Uber Eats, Hello Fresh, etc.)',
];

export default {
	SUPPORTS,

	components: {
		AppScreen,
		AppButton,
	},

	props: {
		back: {
			type: [ String, Object ],
			required: true,
		},
		next: {
			type: [ String, Object ],
			required: true,
		},
	},

	computed: {
		...mapState( [ 'supports' ] ),

		mainSupports: {
			get() {
				return this.supports.filter( v => SUPPORTS.includes( v ) );
			},
			set( supports ) {
				this.updateSupports( [
					...supports,
					...this.otherSupports,
				] );
			},
		},
		otherSupports: {
			get() {
				return this.supports.filter( v => ! SUPPORTS.includes( v ) );
			},
			set( supports ) {
				this.updateSupports( [
					...this.mainSupports,
					...supports,
				] );
			},
		},
	},

	methods: {
		...mapMutations( [ 'updateSupports' ] ),

		addSupport() {
			this.otherSupports = [ ...this.otherSupports, '' ];
		},
		updateSupport( index, value ) {
			const supports = [ ...this.otherSupports ];
			supports[ index ] = value;
			this.otherSupports = supports;
		},
		deleteSupport( index ) {
			const supports = [ ...this.otherSupports ];
			supports.splice( index, 1 );
			this.otherSupports = supports;
		},
	},
};
</script>
