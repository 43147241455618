<template>
	<div class="carousel">
		<div
			ref="scroller"
			class="carousel__slides-wrap"
			:style="{
				'--count': $slots.default.length,
			}"
			@scroll="muteTracking"
		>
			<ul
				ref="wrapper"
				class="carousel__slides"
			>
				<Slide
					v-for="(slide, index) in $slots.default"
					:key="index"
					:content="slide"
					:is-current="index === current"
					:class="`carousel__slide--${ index + 1 }`"
					:aria-hidden="index !== current"
					@enter="onEnter( index )"
				/>
			</ul>
		</div>
		<div class="carousel__nav">
			<button class="carousel__nav-button prev" :disabled="current === 0" @click="gotoSlide( current - 1 )">
				<ScreenReaderText>Previous</ScreenReaderText>
			</button>
			<button
				:class="{
					'carousel__nav-button': true,
					'next': true,
					'nudge': ! interacted,
				}"
				:disabled="current === total - 1"
				@click="gotoSlide( current + 1 )"
			>
				<ScreenReaderText>Next</ScreenReaderText>
			</button>
		</div>
	</div>
</template>

<script>
import AppCarouselSlide from './AppCarouselSlide.vue';
import ScreenReaderText from './ScreenReaderText.vue';

const Slide = {
	props: {
		content: {
			type: Object,
			required: true,
		},
		isCurrent: Boolean,
	},
	render( createElement ) {
		return createElement(
			AppCarouselSlide,
			{
				on: this.$listeners,
				class: { 'is-current-slide': this.isCurrent },
			},
			[ this.content ],
		);
	},
};

export default {
	emits: [ 'update' ],
	components: {
		Slide,
		ScreenReaderText,
	},
	props: {
		auto: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			interacted: false,
			current: 0,
			total: 0,
		};
	},
	watch: {
		current( index ) {
			this.gotoSlide( index );
		},
	},
	mounted() {
		this.gotoSlide( 0 );
		this.interacted = false;

		if ( this.auto ) {
			this.autoInterval = setInterval( () => {
				this.nextSlide();
			}, this.auto );
		}
	},
	methods: {
		gotoSlide( index ) {
			const { scroller, wrapper } = this.$refs;

			const slide = wrapper.children[ index ];
			if ( slide ) {
				scroller.scrollLeft = slide.offsetLeft - ( scroller.offsetWidth / 2 );
			}

			this.total = wrapper.children.length;
			this.interacted = true;
		},
		nextSlide() {
			if ( this.current < this.$slots.default.length ) {
				this.current++;
			}
		},
		muteTracking() {
			// Mute onEnter calls while scrolling
			this.isMuted = true;

			// Unmute after scrolling stops, and apply
			// last onEnter change for real
			clearTimeout( this.unmuteTimeout ?? null );
			this.unmuteTimeout = setTimeout( () => {
				this.isMuted = false;
				this.current = this.lastCurrent;
			}, 50 );
		},
		onEnter( index ) {
			this.lastCurrent = index;

			// If muted, don't actually update until scrolling stops
			if ( ! this.isMuted ) {
				this.current = this.lastCurrent;
			}
		},
	},
};
</script>
